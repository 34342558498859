import { z } from 'zod';

export const verificationCodeSchema = z.object({
  'code-0': z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
  'code-1': z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
  'code-2': z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
  'code-3': z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
  'code-4': z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
  'code-5': z
    .string()
    .min(1, {
      message: 'error.mandatoryField',
    }),
});

export type VerificationCodeFormValues = z.infer<typeof verificationCodeSchema>
