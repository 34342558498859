import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { useAppSelector, useAppDispatch } from 'hooks/useRedux';
import { getUserFilterPreferences, saveUserFilterPreferences } from 'services/UserService';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { fetchMostActiveBankAccounts } from 'store/most-active-bank-accounts/mostActiveBankAccountsSlice';
import { mostActiveBankAccountsSelector } from 'store/most-active-bank-accounts/selectors';
import { currentWorkspaceSelector, userSelector } from 'store/user/selectors';

import { InsightsComponents } from 'constants/enums';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { BankAccount } from 'models/bankAccount.interface';
import { GenericOption } from 'models/option.interface';
import { getBankLogo } from 'utils/bank';
import { handleApiErrors } from 'utils/errorUtils';
import {
  formatAccountSearchLabel,
  formatAcctNumberNameAndType,
} from 'utils/formatters';
import { formatPreferenceFiltersToInsightsFilters } from 'utils/insights';

const getBankAccountsAsOptions = (accounts?: BankAccount[]) => (
  accounts?.map((acc) => ({
    label: formatAccountSearchLabel(acc),
    formattedLabel: formatAcctNumberNameAndType(acc),
    value: acc?.id,
    icon: getBankLogo(acc.institution),
  })) as GenericOption[]
);

export const useInsightsFilters = <T>({ defaultFilters, pageName }: any) => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { data: mostActiveBankAccounts } = useAppSelector(mostActiveBankAccountsSelector, isEqual);
  const { data: storedAccounts } = useAppSelector(accountsSelector, isEqual);
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const loggedUser = useAppSelector(userSelector);
  const workspaceId = currentWorkspace?.id || '';
  const [activeBankAccount, setActiveBankAccount] = useState('');
  const [tempAccounts, setTempAccounts] = useState<(GenericOption | null)[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<GenericOption[]>([]);
  const [hasSavedFilters, setHasSavedFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<T>(defaultFilters);
  const bankAccounts = storedAccounts || [];

  const prepareFilters = (accounts: BankAccount[]) => {
    const accountsAsOptions = getBankAccountsAsOptions(accounts);
    setTempAccounts([...accountsAsOptions]);
    setSelectedAccounts([...accountsAsOptions]);
    setFilters((prevState) => ({
      ...prevState,
      bankAccountIds: accounts?.map(({ id }) => id),
    }));

    if (pageName === InsightsComponents.moneyMovement) {
      setActiveBankAccount(accounts[0].id);
    }
  };

  useEffect(() => {
    if (loggedUser?.id && workspaceId && pageName && bankAccounts?.length > 0) {
      fetchUserFilterPreferences(loggedUser?.id);
    }
  }, [loggedUser, workspaceId, pageName, mostActiveBankAccounts, bankAccounts]);

  const fetchUserFilterPreferences = async (userId: string) => {
    try {
      const response = await getUserFilterPreferences(workspaceId, userId, pageName);

      if (response?.data?.length > 0) {
        const {
          formattedFilters,
          foundBankAccounts,
        } = formatPreferenceFiltersToInsightsFilters(response?.data, bankAccounts);
        const accountsAsOptions = getBankAccountsAsOptions(foundBankAccounts);
        setTempAccounts([...accountsAsOptions]);
        setSelectedAccounts([...accountsAsOptions]);
        setFilters(formattedFilters);
        setHasSavedFilters(true);

        if (pageName === InsightsComponents.moneyMovement && formattedFilters?.activeBankAccount) {
          setActiveBankAccount(formattedFilters?.activeBankAccount);
        }
        return;
      }

      if (!mostActiveBankAccounts?.length) {
        handleMostActiveBankAccounts();
        return;
      }

      prepareFilters(mostActiveBankAccounts);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handleMostActiveBankAccounts = async () => {
    if (!workspaceId) {
      prepareFilters(bankAccounts?.length > 5 ? bankAccounts?.slice(0, 5) : bankAccounts);
    } else {
      try {
        const data = await dispatch(fetchMostActiveBankAccounts(workspaceId)).unwrap();
        prepareFilters(data);
      } catch (e) {
        prepareFilters(bankAccounts?.length > 5 ? bankAccounts?.slice(0, 5) : bankAccounts);
      }
    }
  };

  const handleResetAll = async () => {
    if (!loggedUser?.id) {
      toast.error(intl.formatMessage({ id: 'error.missingInfoPreferences' }), GENERAL_TOAST_OPTIONS);
      return;
    }

    try {
      saveUserFilterPreferences(workspaceId, loggedUser?.id, { pageName, filter: [] });
      setFilters(defaultFilters);
      setHasSavedFilters(false);

      if (!mostActiveBankAccounts?.length) {
        handleMostActiveBankAccounts();
        return;
      }

      prepareFilters(mostActiveBankAccounts);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  return {
    onResetAll: handleResetAll,
    activeBankAccount,
    setActiveBankAccount,
    tempAccounts,
    setTempAccounts,
    selectedAccounts,
    setSelectedAccounts,
    filters,
    setFilters,
    hasSavedFilters,
    setHasSavedFilters,
  };
};
