import { Box, Typography, useTheme } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  extent, scaleBand, scaleLinear, select,
} from 'd3';
import { get } from 'lodash';
import {
  FC, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useIntl } from 'react-intl';

import BankXYAxis from 'charts/BankXYAxis';
import XYAxis from 'charts/XYAxis';
import ActivityLegendItem from 'components/ActivityLegendItem';
import ChartTooltip from 'components/ChartTooltip';
import TooltipCategoryInfo from 'components/TooltipCategoryInfo';

import { useChartResize, useChartXDateTicks } from 'hooks/useCharts';

import { PositionType } from 'constants/enums';
import { Institution } from 'models/bankAccount.interface';
import { AccountActivityRanked } from 'models/charts.interface';
import {
  DEBITS_CHART_LABEL,
  CREDITS_CHART_LABEL,
  ACTIVITY_RANKED_CHART_SETTINGS,
  TOTAL_TRANSACTIONS_CHART_LABEL,
} from 'utils/activityChartConfig';
import { getBankLogo } from 'utils/bank';
import {
  drawGradient,
  getAxisBasedOnPosition,
  getNextValue,
  getRightAxisMargin,
  drawGrid,
  drawHoverLine,
  getPaddingInner,
  getRoundedBarDef,
  extractAccountNumberFromChartInfo,
} from 'utils/chart';
import { getAccountLast4Numbers } from 'utils/formatters';

interface ActivityRankedChartProps {
  data: AccountActivityRanked[];
  hasLegend?: boolean;
}

const ActivityRankedChart: FC<ActivityRankedChartProps> = ({ data, hasLegend }) => {
  const intl = useIntl();
  const maxY = useMemo(
    () => Math.max(...data.map(({ totalNumberTransactions }) => totalNumberTransactions)),
    [data],
  );
  const [activeAccountNumber, setActiveAccountNumber] = useState<string | null>(null);
  const theme = useTheme();
  const gridRef = useRef<SVGGElement>(null);
  const xDomain = useMemo(() => (
    data.map(({ accountNumber, accountId }) => `${accountId}_(${getAccountLast4Numbers(accountNumber)})`)
  ), [data]);
  const xImageDomain = useMemo(() => (
    data.map(({ accountId }) => accountId)
  ), [data]);
  const xTransactionDomain = useMemo(() => (
    data.map(({ totalNumberTransactions, accountId }) => (`${accountId}_${totalNumberTransactions}`))
  ), [data]);

  const activeActivity = useMemo(() => (
    activeAccountNumber
      ? data.find((activity) => (
        `${activity.accountId}_(${getAccountLast4Numbers(activity.accountNumber)})` === activeAccountNumber))
      : null
  ), [activeAccountNumber]);

  // Define the size of the area that we're going to use for the charts and the margins
  const margin = useMemo(() => ({
    top: 10,
    right: getRightAxisMargin(maxY),
    bottom: 65,
    left: 0,
  }), [maxY]);
  const { width, height } = useChartResize(margin, 'activity-ranked-chart');

  // Determinate the ranges of the x and y domains
  const scaleXAccountNumber = useMemo(() => (
    scaleBand()
      .domain(xDomain)
      .range([1, width - 16])
      .paddingInner(getPaddingInner(data?.length))
      .paddingOuter(0.1)
  ), [width, xDomain]);

  const scaleXTransactions = useMemo(() => (
    scaleBand()
      .domain(xTransactionDomain)
      .range([1, width - 16])
      .paddingInner(getPaddingInner(data?.length))
      .paddingOuter(0.1)
  ), [width, xTransactionDomain]);

  const scaleXImage = useMemo(() => (
    scaleBand()
      .domain(xImageDomain)
      .range([1, width - 16])
      .paddingInner(getPaddingInner(data?.length))
      .paddingOuter(0.1)
  ), [width, xImageDomain]);

  const scaleY = useMemo(() => (
    scaleLinear()
      .domain([0, getNextValue(maxY)])
      .range([height, 0])
  ), [height]);

  const removeExistingChart = () => {
    const chart = select('#activity-ranked-chart')
      .select('.chart');

    chart
      .selectAll('.bar')
      .remove();
    chart
      .select('.placeholder-line-bars')
      .selectAll('line')
      .remove();
    chart
      .select('.placeholder-bars')
      .selectAll('.placeholder-bar')
      .remove();
    chart
      .select('.activity-line-bars')
      .selectAll('line')
      .remove();
    chart
      .selectAll('linearGradient')
      .remove();
  };

  const prepareGroupedData = () => {
    const groups = ['totalNumberDebits', 'totalNumberCredits'];
    const groupedData = groups.map((key) => ({
      key,
      values: data.map((d) => ({ key, value: get(d, key), data: d })),
    }));
    const gradients = {
      creditsGradient: [
        { offset: '0%', color: theme.palette.primary.main },
        { offset: '50.52%', color: alpha(theme.palette.general.darkGreen3, 0.9) },
        { offset: '100%', color: alpha(theme.palette.general.darkGreen3, 0.8) },
      ],
      debitsGradient: [
        { offset: '0%', color: theme.palette.general.lightRed1 },
        { offset: '50.52%', color: alpha(theme.palette.general.lightRed1, 0.8) },
        { offset: '100%', color: alpha(theme.palette.general.lightRed1, 0.6) },
      ],
    };

    drawBars(groupedData);
    drawGradient('.activity-ranked', 'debitsGradient', gradients.debitsGradient);
    drawGradient('.activity-ranked', 'creditsGradient', gradients.creditsGradient);
  };

  const drawBars = (groupedData: any[]) => {
    const chart = select('.activity-ranked');
    const bar = chart
      .append('g')
      .attr('class', 'bar')
      .selectAll('g')
      .data(groupedData)
      .enter();

    bar
      .append('g')
      .attr('fill', (d) => {
        const isDebit = d.key === 'totalNumberDebits';
        return `url(${isDebit ? '#debitsGradient' : '#creditsGradient'})`;
      })
      .selectAll('path')
      .data((d) => d.values)
      .enter()
      .append('path')
      .attr('width', scaleXTransactions.bandwidth() / 2)
      .attr('d', (d: any) => {
        const borderRadius = d.value ? 1 : 0;
        const isDebit = d.key === 'totalNumberDebits';

        return getRoundedBarDef(
          d.value,
          `${d.data.accountId}_(${getAccountLast4Numbers(d.data.accountNumber)})`,
          scaleXAccountNumber,
          scaleY,
          borderRadius,
          isDebit ? scaleXTransactions.bandwidth() / 2 : 0,
          scaleXTransactions.bandwidth() / 2,
        );
      });
  };

  const customExtend = useCallback(() => {
    const [min = 0, max = 0] = extent([0, getNextValue(maxY)]);

    return [min, max];
  }, [maxY]);

  const drawTotalActivityLines = () => {
    const chart = select('#activity-ranked-chart')
      .select('.activity-line-bars');
    const line = chart
      .selectAll()
      .data(data)
      .enter();

    line
      .append('line')
      .attr('stroke', theme.palette.general.darkGrey1)
      .attr('stroke-width', '1.5px')
      .attr('transform', `translate(${(scaleXTransactions.bandwidth() / 2)},0)`)
      .attr('x1', (d) => scaleXTransactions(`${d.accountId}_${d.totalNumberTransactions}`) || 0)
      .attr('y1', (d) => scaleY(d.totalNumberTransactions))
      .attr('x2', (d) => scaleXTransactions(`${d.accountId}_${d.totalNumberTransactions}`) || 0)
      .attr('y2', height);

    line
      .append('line')
      .attr('stroke', theme.palette.general.darkGrey1)
      .attr('stroke-width', '1.5px')
      .attr('transform', `translate(${(scaleXTransactions.bandwidth() / 2)},0)`)
      .attr('x1', (d) => (scaleXTransactions(`${d.accountId}_${d.totalNumberTransactions}`) || 0) - 3)
      .attr('y1', (d) => scaleY(d.totalNumberTransactions))
      .attr('x2', (d) => (scaleXTransactions(`${d.accountId}_${d.totalNumberTransactions}`) || 0) + 3)
      .attr('y2', (d) => scaleY(d.totalNumberTransactions));
  };

  const drawPlaceholderBars = () => {
    const [min, max] = customExtend();
    const lineGroup = select('#activity-ranked-chart')
      .select('.placeholder-line-bars');

    const placeholderBars = select('#activity-ranked-chart')
      .select('.placeholder-bars')
      .selectAll()
      .data(xDomain)
      .enter()
      .append('g');

    placeholderBars
      .append('path')
      .attr('class', 'placeholder-bar')
      .attr('fill', alpha(theme.palette.primary.main, 0.07))
      .attr('d', (item) => (
        `M${(scaleXAccountNumber(item.toString()) || 0) - 8}, ${scaleY(max)}
         a${4},${4} 0 0 1 ${4},${-0}
         h${scaleXAccountNumber.bandwidth() + 8}
         a${4},${4} 0 0 1 ${4},${0} 
         v${scaleY(min)}  
         h${-(scaleXAccountNumber.bandwidth()) - 16}Z`
      ))
      .attr('opacity', 0)
      .on('mouseenter', (event, d: any) => {
        select(event.target)
          .attr('opacity', 1);
        setActiveAccountNumber(d);
        lineGroup
          .select('line')
          .style('opacity', 1)
          .attr('transform', `translate(${(scaleXAccountNumber.bandwidth() / 2)},0)`)
          .attr('x1', () => scaleXAccountNumber(d?.toString()) || 0)
          .attr('y1', () => 0)
          .attr('x2', () => scaleXAccountNumber(d?.toString()) || 0)
          .attr('y2', height);
      })
      .on('mouseleave', (event) => {
        select(event.target)
          .attr('opacity', 0);
        setActiveAccountNumber(null);
        lineGroup
          .select('line')
          .style('opacity', 0);
      });
  };

  useEffect(() => {
    removeExistingChart();
    prepareGroupedData();
    drawTotalActivityLines();
    drawGrid(gridRef.current as SVGGElement, theme.palette.general.lightGrey6, scaleY, width);
    drawHoverLine('#activity-ranked-chart', theme.palette.general.lightBlack);
    drawPlaceholderBars();
  }, [height, width]);

  const xTicksAccounts = useChartXDateTicks(xDomain, width);
  const xTicksImages = useChartXDateTicks(xImageDomain, width);
  const xTicksTransactions = useChartXDateTicks(xTransactionDomain, width);

  return (
    <div id="activity-ranked-chart">
      <svg
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      >
        <g className="chart" transform={`translate(${margin.left}, ${margin.top})`}>
          <g ref={gridRef} />
          <g className="activity-ranked" />
          <g className="activity-line-bars" />

          <BankXYAxis
            data={data}
            scale={scaleXImage}
            transform={`translate(0, ${height})`}
            position={PositionType.bottom}
            onTickFormat={(d) => getBankLogo({
              code: d.institutionCode,
              smallLogoUrl: d.institutionLogo,
            } as Institution)}
            tickValues={xTicksImages}
          />
          <XYAxis
            hideAxe
            axisHandler={getAxisBasedOnPosition(PositionType.bottom)}
            scale={scaleXAccountNumber}
            tickValues={xTicksAccounts}
            transform={`translate(0, ${height + 35})`}
            onTickFormat={(value) => extractAccountNumberFromChartInfo(value, true)}
          />
          <XYAxis
            hideAxe
            axisHandler={getAxisBasedOnPosition(PositionType.bottom)}
            scale={scaleXTransactions}
            tickValues={xTicksTransactions}
            transform={`translate(0, ${height + 55})`}
            onTickFormat={(value) => `(${value.toString().split('_')[1]})`}
          />
          <XYAxis
            hideAxe
            axisHandler={getAxisBasedOnPosition(PositionType.right)}
            scale={scaleY}
            maxTicks={5}
            transform={`translate(${width}, 0)`}
          />
          <g className="placeholder-line-bars" />
          <g className="placeholder-bars" />

          {activeActivity && (
            <ChartTooltip
              open
              key={`${activeActivity.institutionCode}-${0}`}
              arrow
              placement="left"
              title={(
                <>
                  <Typography variant="body3" color="general.darkGrey1" fontWeight={600} mb={2} component="p">
                    {`${activeActivity.institutionName || ''}`}
                    {' '}
                    {`(${getAccountLast4Numbers(activeActivity.accountNumber)})`}
                  </Typography>
                  <Box display="flex" flexDirection="column" gap={2}>
                    {ACTIVITY_RANKED_CHART_SETTINGS.map((chartSettings) => {
                      const transactionAmount = chartSettings?.fieldName1
                        ? get(activeActivity, chartSettings?.fieldName1)
                        : null;
                      const value = chartSettings?.fieldName2
                        ? get(activeActivity, chartSettings?.fieldName2)
                        : null;

                      return (
                        <TooltipCategoryInfo
                          key={`tooltip-${chartSettings.id}`}
                          value={value}
                          label={intl.formatMessage({ id: chartSettings.label })}
                          rowSettings={chartSettings}
                          transactionAmount={transactionAmount}
                        />
                      );
                    })}
                  </Box>
                </>
              )}
            >
              <circle
                cx={(scaleXAccountNumber(
                  `${activeActivity.accountId}_(${getAccountLast4Numbers(activeActivity.accountNumber)})`,
                ))}
                cy={scaleY(0)}
                r={0}
              />
            </ChartTooltip>
          )}
        </g>
      </svg>

      {hasLegend && (
        <Box mt={10} display="flex" alignItems="center" gap={5}>
          <ActivityLegendItem
            legendItem={TOTAL_TRANSACTIONS_CHART_LABEL}
            label={intl.formatMessage({ id: TOTAL_TRANSACTIONS_CHART_LABEL.label })}
          />
          <ActivityLegendItem
            legendItem={CREDITS_CHART_LABEL}
            label={intl.formatMessage({ id: CREDITS_CHART_LABEL.label })}
          />
          <ActivityLegendItem
            legendItem={DEBITS_CHART_LABEL}
            label={intl.formatMessage({ id: DEBITS_CHART_LABEL.label })}
          />
        </Box>
      )}
    </div>
  );
};

export default ActivityRankedChart;
