import MuiChip from '@mui/material/Chip';
import MuiDivider from '@mui/material/Divider';
import { alpha, styled } from '@mui/material/styles';

export const Divider = styled(MuiDivider)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  height: '24px',
  width: '1px',
}));

export const BankImg = styled('img')(() => ({
  height: '16px',
  width: '16px',
  borderRadius: '2px',
}));

export const Bubble = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.general.lightGreen6,
  borderRadius: '20px',
  padding: theme.spacing(0.5, 2),
}));

export const Chip = styled(
  MuiChip,
  { shouldForwardProp: (props) => props !== 'asChip' && props !== 'active' },
)<{ asChip?: boolean; active?: boolean }>(({ theme, asChip, active }) => ({
  // eslint-disable-next-line no-nested-ternary
  backgroundColor: active
    ? alpha(theme.palette.primary.main, 0.18)
    : asChip ? theme.palette.common.white : 'transparent',
  border: active ? `2px solid ${theme.palette.primary.main}` : 'none',

  '&:hover': {
    backgroundColor: asChip ? 'inherit' : 'transparent',
  },
}));

export const UnorderList = styled('ul')(({ theme }) => ({
  paddingLeft: theme.spacing(6),
}));
