import { Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import CardType from 'components/CardType';

import { NOT_APPLICABLE } from 'constants/general';

interface RecipientAccountDetailsProps {
  isDebit: boolean;
  accountNumberTail: string;
  accountName?: string;
  accountTypeLabel?: string;
}

const RecipientAccountDetails: FC<RecipientAccountDetailsProps> = ({
  isDebit,
  accountTypeLabel,
  accountNumberTail,
  accountName,
}) => {
  const intl = useIntl();

  return (
    <div>
      <Typography variant="body2" className="text-ellipsis" fontWeight={600}>
        {!accountName
          ? `(${accountNumberTail})`
          : `${accountName} (${accountNumberTail})`}
      </Typography>

      {isDebit ? (
        <CardType label={intl.formatMessage({ id: 'label.debit' })} />
      ) : (
        <Typography variant="body3" fontWeight={400} color="textSecondary">
          {accountTypeLabel ? intl.formatMessage({ id: accountTypeLabel }) : NOT_APPLICABLE}
        </Typography>
      )}
    </div>
  );
};

export default RecipientAccountDetails;
