import { styled } from '@mui/material/styles';

export const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(7, 10, 10, 10),
  position: 'relative',
  width: '100%',
  backgroundColor: theme.palette.background.secondary,

  [theme.breakpoints.down('md')]: {
    width: 'fit-content',
    minWidth: '100%',
  },
}));
