import { styled } from '@mui/material/styles';

export const TypeWrapper = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.border.secondary}`,
  padding: theme.spacing(0.5, 2),
  backgroundColor: theme.palette.general.lightGrey2,
  textTransform: 'uppercase',
  borderRadius: '4px',
  width: 'fit-content',
}));
