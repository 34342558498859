import { lazy } from 'react';

import CustomLazyLoader from 'routes/CustomLazyLoader';

const AccountStatements = lazy(
  () => CustomLazyLoader(
    () => import(
      /* webpackChunkName: 'AccountStatements' */ 'modules/account-statements/containers/Statements'
    ),
    'AccountStatements',
  ),
);

export default AccountStatements;
