import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { useAppSelector } from 'hooks/useRedux';
import { userSelector } from 'store/user/selectors';

import { User } from 'models/user.interface';
import { formatUserName } from 'utils/formatters';

interface UserNameProps {
  user: User;
}

const UserName: FC<UserNameProps> = ({ user }) => {
  const intl = useIntl();
  const loggedUser = useAppSelector(userSelector);

  return (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="table">
        {formatUserName(user)}
      </Typography>

      {loggedUser?.id === user?.id && (
        <Typography variant="table" color="general.darkGrey1">
          {`(${intl.formatMessage({ id: 'label.you' })})`}
        </Typography>
      )}
    </Box>
  );
};

export default UserName;
