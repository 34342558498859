import { runPostApiRequestFB, runPostApiRequest } from 'services/Api';

import { SEND_CODE_API, VERIFY_CODE_API } from 'constants/apiRoutes';

export const verifyCode = async (phoneNumber: string, code: string) => (
  runPostApiRequest(VERIFY_CODE_API, { phoneNumber, code })
);

export const sendCode = async (phoneNumber: string) => (
  runPostApiRequest(SEND_CODE_API, { phoneNumber })
);

export const verifyCodeFB = async (phoneNumber: string, code: string) => (
  runPostApiRequestFB(VERIFY_CODE_API, { phoneNumber, code })
);

export const sendCodeFB = async (phoneNumber: string) => (
  runPostApiRequestFB(SEND_CODE_API, { phoneNumber })
);
