import { styled } from '@mui/material/styles';

export const BankImg = styled('img')(() => ({
  height: '24px',
  width: '24px',
  borderRadius: '2px',
}));

export const RowWrapper = styled('div')<{
  isMenuOpen: boolean;
  hasHoverEffect: boolean;
  requiresRelink: boolean;
}>(
  ({
    theme,
    hasHoverEffect,
    isMenuOpen,
    requiresRelink,
  }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    height: '50px',
    padding: theme.spacing(2, 3),
    margin: theme.spacing(0, 1),
    cursor: 'pointer',

    '& p,span': {
      color: requiresRelink ? theme.palette.general.darkOrange : theme.palette.text.primary,
    },

    '& .lastUpdatedAt': {
      visibility: 'hidden',
    },

    ...(isMenuOpen ? {
      padding: theme.spacing(2, 1.5, 2, 3),
      '& .menuBankName': {
        marginLeft: theme.spacing(2.5),
      },

      '& .bankName': {
        maxWidth: '150px',

        [theme.breakpoints.down('md')]: {
          maxWidth: '100px',
        },
      },
    } : {}),

    ...(hasHoverEffect ? {
      '&:hover': {
        padding: theme.spacing(2, 1.5, 2, 3),
        backgroundColor: theme.palette.general.lightGrey2,
        borderRadius: '8px',

        '& .bankName': {
          maxWidth: '130px',

          [theme.breakpoints.down('md')]: {
            maxWidth: '80px',
          },
        },

        '& .menuBankName': {
          display: 'initial',
          marginLeft: theme.spacing(2.5),

          '& button': {
            '&:hover': {
              backgroundColor: theme.palette.border.secondary,
            },
          },
        },

        '& .lastUpdatedAt': {
          visibility: 'visible',
        },
      },
    } : {}),
  }),
);

export const IconWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& path': {
    fill: theme.palette.warning.secondary,
  },
}));

export const InstitutionWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: theme.spacing(2),
  maxWidth: '200px',

  [theme.breakpoints.down('md')]: {
    maxWidth: '100px',
  },
}));
