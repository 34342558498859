import { useTheme } from '@mui/material';
import {
  ascending,
  axisLeft,
  scaleBand,
  scaleLinear,
  select,
} from 'd3';
import { uniqBy } from 'lodash';
import { FC, useMemo, useRef } from 'react';

import LineChart from 'charts/LineChart';
import XYAxis from 'charts/XYAxis';
import LineChartTooltip from 'components/LineChartTooltip';

import { useChartResize, useChartXDateTicks } from 'hooks/useCharts';

import { DateFormats, PositionType } from 'constants/enums';
import { getAxisBasedOnPosition, getNextValue, getRightAxisMargin } from 'utils/chart';
import { formatChartBalance, formatDate } from 'utils/formatters';

interface ActivityChatProps {
  data: { x: number; y: number }[][];
}

const ActivityChat: FC<ActivityChatProps> = ({ data }) => {
  const theme = useTheme();
  const gridRef = useRef<SVGGElement>(null);

  const mainColor = theme.palette.success.secondary;
  const maxAbsVal = useMemo(() => (
    Math.max(...data[0].map(({ y }) => Math.abs(y)))
  ), [data]);

  const margin = useMemo(() => ({
    top: 10,
    right: getRightAxisMargin(maxAbsVal),
    bottom: 24,
    left: 0,
  }), [maxAbsVal]);
  const { width, height } = useChartResize(margin, 'activity-chart', 150);
  const scaleXData = useMemo(() => {
    const xData = data[0] || [];
    const uniqData = uniqBy(xData, 'x');
    return uniqData
      .map(({ x }) => x.toString())
      .sort(ascending);
  }, [data]);
  const xTicks = useChartXDateTicks(scaleXData, width);
  const scaleXBand = useMemo(() => (
    scaleBand()
      .domain(scaleXData)
      .range([0, width - 16])
  ), [width, scaleXData]);

  const scaleY = useMemo(() => (
    scaleLinear()
      .domain([0, getNextValue(maxAbsVal)])
      .range([height, 0])
  ), [height]);

  const yTicks = scaleY.ticks(5).slice(1);

  const makeYLines = () => (
    axisLeft(scaleY)
      .tickValues(yTicks)
      .tickSize(-width + 16)
      .tickPadding(30)
      .tickSizeOuter(0)
      .scale(scaleY)
  );

  select(gridRef.current as SVGGElement)
    .attr('class', 'grid')
    .style('color', theme.palette.common.white)
    .style('stroke-dasharray', '4 4')
    .attr('stroke-width', '0.5px')
    .attr('transform', 'translate(4, 0)')
    .call(makeYLines())
    .call((g) => g.select('.domain').remove());

  return (
    <div id="activity-chart">
      <svg
        width={width + margin.left + margin.right}
        height={height + margin.top + margin.bottom}
      >
        <g transform={`translate(${margin.left}, ${margin.top})`}>
          <LineChart
            data={data[0]}
            color={mainColor}
            scaleX={scaleXBand}
            scaleY={scaleY}
            boxShadow="rgba(36, 96, 93, 0.25)"
          />
          <XYAxis
            color={theme.palette.common.white}
            axisHandler={getAxisBasedOnPosition(PositionType.right)}
            hideAxe
            scale={scaleY}
            transform={`translate(${width}, 0)`}
            onTickFormat={(value) => formatChartBalance({ price: +value })}
          />
          <XYAxis
            color={theme.palette.common.white}
            axisHandler={getAxisBasedOnPosition(PositionType.bottom)}
            scale={scaleXBand}
            tickPadding={12}
            tickValues={xTicks}
            transform={`translate(4, ${height})`}
            onTickFormat={(value) => formatDate(+value, DateFormats.shortDate)}
          />
          <g ref={gridRef} />
          {data[0].map((lineCoordinates) => (
            <LineChartTooltip
              key={`${lineCoordinates.x}-${lineCoordinates.y}`}
              line={lineCoordinates}
              scaleX={scaleXBand}
              scaleY={scaleY}
              circleColor={mainColor}
              isVisible={data[0]?.length === 1}
            />
          ))}
        </g>
      </svg>
    </div>
  );
};

export default ActivityChat;
