import { styled } from '@mui/material/styles';

import NumberFormatField from 'fields/NumberFormatField';

export const NumberField = styled(NumberFormatField)<{ error: boolean }>(({ theme, error }) => ({
  borderRadius: 0,

  '& input': {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32.74px',
    textAlign: 'center',
    padding: theme.spacing(4),
  },

  '& fieldset': {
    border: 'none !important',
    borderBottom: `2px solid ${error ? theme.palette.error.main : theme.palette.border.secondary} !important`,
    borderRadius: 0,
  },

  '&:focus-within fieldset': {
    borderBottom: `2px solid ${theme.palette.primary.main} !important`,
  },
}));
