import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Box, Typography } from '@mui/material';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import CompanyForm from 'components/CompanyForm';
import ConfirmationModal from 'components/ConfirmationModal';
import CustomButton from 'components/CustomButton';
import VerificationCode from 'containers/VerificationCode';

import { useWorkspaceAuth } from 'hooks/useWorkspaceAuth';
import { sendCodeFB } from 'services/VerificationCodeService';

import CloseIcon from 'assets/icons/CloseIcon';
import LogoText from 'assets/images/logo-text-white-beta.svg';
import envConfig from 'configuration/env';
import { ConfirmationTypes } from 'constants/enums';
import { GENERAL_TOAST_OPTIONS, VERIFICATION_CODE_MAX_COUNT } from 'constants/general';
import { User } from 'models/user.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { getPlainPhoneNumber } from 'utils/formatters';
import {
  DummyCompanyFormValues,
  dummyCompanyFormSchema,
  WorkspaceFormValues,
} from 'utils/validation/workspaceFormSchema';

import {
  Background, CardMedia, Header, IconButton,
} from './index.styled';

interface CreateDummyCompanyProps {
  onSubmit: (values: DummyCompanyFormValues) => void;
  currentUser: User|null;
  onClose: () => void;
}

const CreateDummyCompany:FC<CreateDummyCompanyProps> = ({
  onSubmit,
  currentUser,
  onClose,
}) => {
  const intl = useIntl();
  const { createAndSwitchWorkspace } = useWorkspaceAuth();
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [isVerificationOpen, setIsVerificationOpen] = useState<boolean>(false);
  const [triesCount, setTriesCount] = useState<number>(VERIFICATION_CODE_MAX_COUNT);

  const {
    register,
    control,
    handleSubmit,
    setError,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<DummyCompanyFormValues>({
    resolver: zodResolver(dummyCompanyFormSchema),
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      phoneNumber: currentUser?.phoneNumber || '',
    },
  });
  const formPhoneNumber = watch('phoneNumber') || '';

  const handleCreateCompany = async (values: DummyCompanyFormValues) => {
    await createAndSwitchWorkspace(
      values,
      (newWorkspace: WorkspaceFormValues) => {
        toast.success(
          intl.formatMessage(
            { id: 'label.successfullySwitchedWorkspace' },
            { displayName: newWorkspace.displayName },
          ),
          GENERAL_TOAST_OPTIONS,
        );

        onSubmit(values);
      },
      (error) => {
        if (isVerificationOpen) {
          setIsVerificationOpen(false);
        }

        handleApiErrors<WorkspaceFormValues>(error, setError);
      },
    );
  };

  const handleSubmitForm = async ({ phoneNumber }: DummyCompanyFormValues) => {
    try {
      const plainNumber = getPlainPhoneNumber(phoneNumber);
      await sendCodeFB(plainNumber);
      setIsVerificationOpen(true);
    } catch (e) {
      handleApiErrors(e);
    }
  };

  const handleChangeTriesCount = (newCount: number) => {
    if (newCount) {
      setTriesCount(newCount);
      return;
    }

    setIsAlertOpen(true);
    setIsVerificationOpen(false);
  };

  const handleExitVerification = () => {
    setIsAlertOpen(false);
    setTriesCount(VERIFICATION_CODE_MAX_COUNT);
  };

  const handleClose = () => {
    setIsVerificationOpen(false);
    setTriesCount(VERIFICATION_CODE_MAX_COUNT);
  };

  if (isAlertOpen) {
    return (
      <ConfirmationModal
        isOpen
        id="modal-verificationCodeError"
        type={ConfirmationTypes.Error}
        title={intl.formatMessage({ id: 'label.verificationCodeIncorrect' })}
        confirmLabel={intl.formatMessage({ id: 'button.okay' })}
        onSubmit={handleExitVerification}
        backdrop={{ sx: { display: 'none' } }}
      >
        <Box textAlign="center" display="flex" justifyContent="center">
          <Typography color="textSecondary" width="325px">
            {intl.formatMessage({ id: 'modalContent.exceededNumberOfTries' })}
          </Typography>
        </Box>
      </ConfirmationModal>
    );
  }

  if (isVerificationOpen && !isAlertOpen) {
    return (
      <Box px={6} pt={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h2">
            {intl.formatMessage({ id: 'label.verificationCode' })}
          </Typography>
          <IconButton onClick={handleClose} aria-label="close modal" id="iconButton-closeModal">
            <CloseIcon />
          </IconButton>
        </Box>

        <VerificationCode
          onSubmit={handleSubmit(handleCreateCompany)}
          mb={7}
          triesCount={triesCount}
          setTriesCount={handleChangeTriesCount}
          phoneNumber={formPhoneNumber}
          useFbToken
        />
      </Box>
    );
  }

  return (
    <>
      <Header>
        <CardMedia
          component="img"
          src={LogoText}
          alt={envConfig.brandName}
        />
        <Typography
          variant="label2"
          fontWeight={600}
          color="general.lightGreen5"
          sx={{ opacity: 0.9, marginLeft: '35px', display: 'block' }}
        >
          {intl.formatMessage({ id: 'label.centralizeYourAccounts' }, { appName: envConfig.brandName })}
        </Typography>
        <Background />
      </Header>

      <Box p={7}>
        <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h1">
            {intl.formatMessage({ id: 'label.newCompany' })}
          </Typography>

          <IconButton onClick={onClose} aria-label="close modal" id="iconButton-close-new-company">
            <CloseIcon />
          </IconButton>
        </Box>

        <CompanyForm
          control={control}
          register={register}
          errors={errors}
          onSubmit={handleSubmit(handleSubmitForm)}
        />

        <CustomButton
          fullWidth
          disabled={isSubmitting}
          onClick={handleSubmit(handleSubmitForm)}
          id="button-submitCreateCompany"
          label={intl.formatMessage({ id: 'button.create' })}
        />
      </Box>
    </>
  );
};

export default CreateDummyCompany;
