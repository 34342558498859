/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ExternalLinkIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.55946 5C5.7093 5 5.00391 5.7054 5.00391 6.55556L5.00391 17.4444C5.00391 18.2946 5.7093 19 6.55946 19H17.4484C18.2985 19 19.0039 18.2946 19.0039 17.4444V12L17.4484 12V17.4444H6.55946L6.55946 6.55556L12.0039 6.55556V5L6.55946 5ZM13.5595 5V6.55556H16.3485L9.12066 13.7834L10.2205 14.8832L17.4484 7.65538V10.4444L19.0039 10.4444V5L13.5595 5Z"
      fill={color}
    />
  </svg>
);

export default ExternalLinkIcon;
