import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { DateFormats } from 'constants/enums';
import { ScheduledTransaction } from 'models/transaction.interface';
import { formatDate } from 'utils/formatters';
import { getRecurrenceStringRule } from 'utils/scheduleTime';

interface ScheduledForProps {
  transaction: ScheduledTransaction;
}

const ScheduledFor: FC<ScheduledForProps> = ({ transaction }) => {
  const intl = useIntl();
  const { startDateTime, recurrenceRule } = transaction;
  const rule = recurrenceRule ? getRecurrenceStringRule(startDateTime, recurrenceRule) : null;

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {startDateTime && (
        <Typography variant="table">
          {formatDate(new Date(startDateTime).getTime(), DateFormats.longDate)}
        </Typography>
      )}
      {rule && (
        <Box sx={{ borderRadius: 1, backgroundColor: 'general.lightBlue1' }} width="fit-content" px={2} py={1}>
          <Typography variant="body3" fontWeight={600}>
            {intl.formatMessage({ id: rule.label }, rule.values)}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ScheduledFor;
