import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Grid2 as Grid } from '@mui/material';
import {
  FC, ReactElement, ReactNode,
} from 'react';
import { Controller, Path, useForm } from 'react-hook-form';

import { NumberField } from 'containers/VerificationCode/CodeSection/index.styled';

import { REGEX_VERIFICATION_CODE } from 'constants/regex';
import { VerificationCodeFormValues, verificationCodeSchema } from 'utils/validation/verificationCodeSchema';

interface CodeSectionProps {
  onSubmit: (verificationCode: VerificationCodeFormValues) => void;
  children: ReactNode;
}

const CodeSection: FC<CodeSectionProps> = ({
  onSubmit,
  children,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<VerificationCodeFormValues>({
    resolver: zodResolver(verificationCodeSchema),
    mode: 'onSubmit',
    defaultValues: {
      'code-0': '',
      'code-1': '',
      'code-2': '',
      'code-3': '',
      'code-4': '',
      'code-5': '',
    },
  });

  const handlePaste = (event: any) => {
    event.preventDefault();
    const pasteData = event.clipboardData
      ? event.clipboardData.getData('text')
      : '';

    if (REGEX_VERIFICATION_CODE.test(pasteData)) {
      pasteData.split('').forEach((digit: string, index: number) => {
        setValue(`code-${index}` as Path<VerificationCodeFormValues>, digit);
      });
    }
  };

  const isAllowed = ({ value }: { value: string }) => value.length <= 1;

  return (
    <form onSubmit={handleSubmit(onSubmit)} onPaste={handlePaste}>
      <Grid container columnSpacing={3}>
        {Array.from({ length: 6 }).map((_, index) => (
          <Grid key={`code-${index}`} size={{ xs: 2 }}>
            <Controller
              name={`code-${index}` as Path<VerificationCodeFormValues>}
              control={control}
              render={({ field }): ReactElement => (
                <NumberField
                  name={`code-${index}`}
                  variant="outlined"
                  value={field.value}
                  InputProps={{
                    ...register(`code-${index}` as Path<VerificationCodeFormValues>),
                    autoComplete: 'off',
                  }}
                  isAllowed={isAllowed}
                  error={!!errors?.[`code-${index}` as Path<VerificationCodeFormValues>]?.message}
                  decimalScale={0}
                />
              )}
            />
          </Grid>
        ))}
      </Grid>
      {children}
    </form>
  );
};

export default CodeSection;
