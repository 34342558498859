import { Box } from '@mui/material';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

import { getValueFromStorage } from 'hooks/useLocalStorage';

import envConfig from 'configuration/env';
import { LocalStorageKeys } from 'constants/enums';

const requestInterceptor = (req: Record<string, any>) => {
  const { idToken } = getValueFromStorage(LocalStorageKeys.jwtToken, null) || {};

  if (idToken) {
    req.headers.Authorization = `Bearer ${idToken}`;
  }

  return req;
};

const SwaggerDocumentation = () => (
  <Box height="100vh" sx={{ overflowY: 'auto' }}>
    <SwaggerUI
      requestInterceptor={requestInterceptor}
      url={`${envConfig.apiBaseUrl}v3/api-docs`}
    />
  </Box>
);

export default SwaggerDocumentation;
