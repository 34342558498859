/* eslint max-len: 0 */

import { ReactElement } from 'react';

import { CustomIconInterface } from 'models/icon.interface';

const ManagementIcon = ({ color = 'currentColor', size = 24 }: CustomIconInterface): ReactElement => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.80001 3.2C3.91601 3.2 3.20001 3.916 3.20001 4.8V19.2C3.20001 20.084 3.91601 20.8 4.80001 20.8H6.04532C5.77012 20.3272 5.60001 19.7856 5.60001 19.2V4.8C5.60001 4.2144 5.77012 3.6728 6.04532 3.2H4.80001ZM8.80001 3.2C7.91601 3.2 7.20001 3.916 7.20001 4.8V19.2C7.20001 20.084 7.91601 20.8 8.80001 20.8H19.2C20.084 20.8 20.8 20.084 20.8 19.2V8.4C20.8 8.188 20.716 7.98477 20.5656 7.83437L16.1656 3.43437C16.0152 3.28397 15.812 3.2 15.6 3.2H8.80001ZM15.2 4.72343L19.2766 8.8H16C15.5584 8.8 15.2 8.4416 15.2 8V4.72343ZM11.2 12H16.8C17.2416 12 17.6 12.3584 17.6 12.8C17.6 13.2416 17.2416 13.6 16.8 13.6H11.2C10.7584 13.6 10.4 13.2416 10.4 12.8C10.4 12.3584 10.7584 12 11.2 12ZM11.2 15.2H15.2C15.6416 15.2 16 15.5584 16 16C16 16.4416 15.6416 16.8 15.2 16.8H11.2C10.7584 16.8 10.4 16.4416 10.4 16C10.4 15.5584 10.7584 15.2 11.2 15.2Z"
      fill={color}
    />
  </svg>

);

export default ManagementIcon;
