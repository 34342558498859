import { Typography, Box } from '@mui/material';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import FilledUserIcon from 'assets/icons/FilledUserIcon';
import { FinancialAccountType } from 'constants/enums';
import { NOT_APPLICABLE } from 'constants/general';
import { FaAccountTransaction } from 'models/faTransaction.interface';
import { getAccountLast4Numbers } from 'utils/formatters';

interface RecipientFinancialAccountDetailsProps {
  recipient: FaAccountTransaction;
}

const RecipientFinancialAccountDetails:FC<RecipientFinancialAccountDetailsProps> = ({ recipient }) => {
  const {
    bankAccountName, accountNumber, faType: accountType,
  } = recipient;
  const intl = useIntl();

  const accountDetails = useMemo(() => {
    if (!accountNumber) {
      return {
        name: bankAccountName || NOT_APPLICABLE,
      };
    }

    if (accountType === FinancialAccountType.bank) {
      return {
        name: bankAccountName,
        accountInfo: intl.formatMessage({ id: 'label.bankPreview' }, { number: getAccountLast4Numbers(accountNumber) }),
      };
    }

    return {
      name: bankAccountName,
      accountInfo: intl.formatMessage({ id: 'label.cardPreview' }, { number: getAccountLast4Numbers(accountNumber) }),
    };
  }, [recipient]);

  return (
    <Box display="flex" alignItems="center" gap={3}>
      <FilledUserIcon size={28} />
      <div>
        <Typography variant="table" fontWeight={600} component="div" className="text-ellipsis">
          {accountDetails.name}
        </Typography>
        {accountDetails.accountInfo && (
          <Typography variant="body3" color="textSecondary">
            {accountDetails.accountInfo}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default RecipientFinancialAccountDetails;
