import { styled } from '@mui/material/styles';

import { ACT_AS_BLOCK, NAVBAR_HEIGHT, TABLET_WIDTH } from 'constants/general';

export const MainContent = styled('div')<{ storedViewAs: boolean }>(({ storedViewAs }) => ({
  marginTop: `${NAVBAR_HEIGHT}px`,
  width: '100%',
  minWidth: TABLET_WIDTH,
  height: `calc(100vh - (${NAVBAR_HEIGHT}px + ${storedViewAs ? ACT_AS_BLOCK : 0}px))`,
  position: 'relative',
  display: 'block',
  overflowY: 'auto',
}));
