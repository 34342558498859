import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getAdvancedTransactionSearch,
  getCashFlowCreditTransactions,
  getCashFlowDebitTransactions,
  getCashFlowInfo,
} from 'services/CashFlowService';
import { getMoneyMovement, getMoneyMovementConsolidated } from 'services/MoneyMovementService';
import { resetStore } from 'store/actions';

import { ApiStatus, SortOrder } from 'constants/enums';
import {
  AdvancedTransactionSearch,
  AdvancedSearchQueryParams,
  CashFlowTransactionSearch,
  CashFlowSearchQueryParams,
  CashFlowDetails,
} from 'models/cashFlow.interface';
import {
  AccountMovementsDetails,
  MoneyMovementsDetails,
} from 'models/moneyMovement.interface';
import { Pagination } from 'models/pagination.interface';
import { SortOptions } from 'models/sortOptions.interface';
import { handleApiErrors } from 'utils/errorUtils';

export interface CashFlowState {
  advancedTransactionSearch: {
    data?: AdvancedTransactionSearch;
    status: ApiStatus;
    sortOptions: SortOptions;
    pagination: Pagination;
    search: string;
  };
  cashFlow: {
    credit: {
      data?: CashFlowTransactionSearch;
      status: ApiStatus;
      sortOptions: SortOptions;
      pagination: Pagination;
    };
    debit: {
      data?: CashFlowTransactionSearch;
      status: ApiStatus;
      sortOptions: SortOptions;
      pagination: Pagination;
    };
  };
  cashFlowDetails: {
    data: CashFlowDetails;
    status: ApiStatus;
  };
  summaryTransactions: {
    transactions?: AccountMovementsDetails[];
    total?: MoneyMovementsDetails;
    status: ApiStatus;
    sortOptions: SortOptions;
  };
}

const initialState: CashFlowState = {
  advancedTransactionSearch: {
    data: undefined,
    status: ApiStatus.idle,
    sortOptions: {
      name: 'createdAt',
      type: SortOrder.desc,
    },
    pagination: {
      totalPages: 0,
      totalElements: 0,
      page: 1,
    },
    search: '',
  },
  cashFlow: {
    credit: {
      data: undefined,
      status: ApiStatus.idle,
      sortOptions: {
        name: 'createdAt',
        type: SortOrder.desc,
      },
      pagination: {
        totalPages: 0,
        totalElements: 0,
        page: 1,
      },
    },
    debit: {
      data: undefined,
      status: ApiStatus.idle,
      sortOptions: {
        name: 'createdAt',
        type: SortOrder.desc,
      },
      pagination: {
        totalPages: 0,
        totalElements: 0,
        page: 1,
      },
    },
  },
  cashFlowDetails: {
    data: {
      totalVolume: 0,
      totalValue: 0,
      startBalance: 0,
      endBalance: 0,
    },
    status: ApiStatus.idle,
  },
  summaryTransactions: {
    transactions: undefined,
    total: undefined,
    status: ApiStatus.idle,
    sortOptions: {
      name: 'createdAt',
      type: SortOrder.desc,
    },
  },
};

export const fetchAdvancedTransactionSearch = createAsyncThunk(
  'cashFlow/fetchAdvancedTransactionSearch',
  async (queryParams: AdvancedSearchQueryParams, thunkAPI) => {
    try {
      const response = await getAdvancedTransactionSearch(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchCreditTransaction = createAsyncThunk(
  'cashFlow/fetchCreditTransaction',
  async (queryParams: CashFlowSearchQueryParams, thunkAPI) => {
    try {
      const response = await getCashFlowCreditTransactions(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchDebitTransaction = createAsyncThunk(
  'cashFlow/fetchDebitTransaction',
  async (queryParams: CashFlowSearchQueryParams, thunkAPI) => {
    try {
      const response = await getCashFlowDebitTransactions(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchCashFlowDetails = createAsyncThunk(
  'cashFlow/fetchCashFlowDetails',
  async (queryParams: CashFlowSearchQueryParams, thunkAPI) => {
    try {
      const response = await getCashFlowInfo(queryParams);
      return response.data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchSummaryTransactions = createAsyncThunk(
  'cashFlow/fetchSummaryTransactions',
  async (queryParams: any, thunkAPI) => {
    try {
      const [moneyMovementsResult, moneyMovementConsolidatedResult] = await Promise.all([
        getMoneyMovement(queryParams),
        getMoneyMovementConsolidated(queryParams),
      ]);

      return {
        moneyMovementsResult: moneyMovementsResult.data,
        moneyMovementsConsolidatedResult: moneyMovementConsolidatedResult.data,
      };
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const cashFlowSlice = createSlice({
  name: 'cashFlow',
  initialState,
  reducers: {
    setAdvancedSearchPagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.advancedTransactionSearch.pagination = {
        ...state.advancedTransactionSearch.pagination,
        ...action.payload,
      };
      return state;
    },
    setAdvancedSearchSort: (state, action: PayloadAction<SortOptions>) => {
      state.advancedTransactionSearch.sortOptions = action.payload;
      return state;
    },
    setAdvancedSearchSearch: (state, action: PayloadAction<string>) => {
      state.advancedTransactionSearch.search = action.payload;
      state.advancedTransactionSearch.pagination.page = 1;
      return state;
    },
    setCashFlowCreditTransactionPagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.cashFlow.credit.pagination = {
        ...state.cashFlow.credit.pagination,
        ...action.payload,
      };
      return state;
    },
    setCashFlowCreditTransactionSort: (state, action: PayloadAction<SortOptions>) => {
      state.cashFlow.credit.sortOptions = action.payload;
      return state;
    },
    setCashFlowDebitTransactionPagination: (state, action: PayloadAction<Partial<Pagination>>) => {
      state.cashFlow.debit.pagination = {
        ...state.cashFlow.debit.pagination,
        ...action.payload,
      };
      return state;
    },
    setCashFlowDebitTransactionSort: (state, action: PayloadAction<SortOptions>) => {
      state.cashFlow.debit.sortOptions = action.payload;
      return state;
    },
    setSummaryTransactionSort: (state, action: PayloadAction<SortOptions>) => {
      state.summaryTransactions.sortOptions = action.payload;
      return state;
    },
    resetCashFlow: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(resetStore, () => initialState)
      .addCase(fetchAdvancedTransactionSearch.pending, (state) => {
        state.advancedTransactionSearch = {
          ...state.advancedTransactionSearch,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchAdvancedTransactionSearch.fulfilled, (state, action) => {
        const { totalElements, totalPages } = action.payload?.result || {};

        state.advancedTransactionSearch = {
          ...state.advancedTransactionSearch,
          data: action.payload,
          status: ApiStatus.idle,
        };
        state.advancedTransactionSearch.pagination = {
          ...state.advancedTransactionSearch.pagination,
          totalPages,
          totalElements,
        };
      })
      .addCase(fetchAdvancedTransactionSearch.rejected, (state) => {
        state.advancedTransactionSearch = {
          ...state.advancedTransactionSearch,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCreditTransaction.pending, (state) => {
        state.cashFlow.credit = {
          ...state.cashFlow.credit,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchCreditTransaction.fulfilled, (state, action) => {
        const { totalElements, totalPages } = action.payload.result || {};

        state.cashFlow.credit = {
          ...state.cashFlow.credit,
          status: ApiStatus.idle,
          data: action.payload,
          pagination: {
            ...state.cashFlow.credit.pagination,
            totalPages,
            totalElements,
          },
        };
      })
      .addCase(fetchCreditTransaction.rejected, (state) => {
        state.cashFlow.credit = {
          ...state.cashFlow.credit,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchDebitTransaction.pending, (state) => {
        state.cashFlow.debit = {
          ...state.cashFlow.debit,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchDebitTransaction.fulfilled, (state, action) => {
        const { totalElements, totalPages } = action.payload.result || {};

        state.cashFlow.debit = {
          ...state.cashFlow.debit,
          status: ApiStatus.idle,
          data: action.payload,
          pagination: {
            ...state.cashFlow.debit.pagination,
            totalPages,
            totalElements,
          },
        };
      })
      .addCase(fetchDebitTransaction.rejected, (state) => {
        state.cashFlow.debit = {
          ...state.cashFlow.debit,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchSummaryTransactions.pending, (state) => {
        state.summaryTransactions = {
          ...state.summaryTransactions,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchSummaryTransactions.fulfilled, (state, action) => {
        const { moneyMovementsResult, moneyMovementsConsolidatedResult } = action.payload;

        state.summaryTransactions = {
          ...state.cashFlow.debit,
          status: ApiStatus.idle,
          transactions: moneyMovementsResult,
          total: moneyMovementsConsolidatedResult,
        };
      })
      .addCase(fetchSummaryTransactions.rejected, (state) => {
        state.summaryTransactions = {
          ...state.summaryTransactions,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCashFlowDetails.pending, (state) => {
        state.cashFlowDetails = {
          ...state.cashFlowDetails,
          status: ApiStatus.loading,
        };
      })
      .addCase(fetchCashFlowDetails.fulfilled, (state, action) => {
        state.cashFlowDetails = {
          data: action.payload,
          status: ApiStatus.idle,
        };
      })
      .addCase(fetchCashFlowDetails.rejected, (state) => {
        state.cashFlowDetails = {
          ...state.cashFlowDetails,
          status: ApiStatus.idle,
        };
      });
  },
});

export const {
  setAdvancedSearchPagination,
  setAdvancedSearchSort,
  setAdvancedSearchSearch,
  setCashFlowCreditTransactionPagination,
  setCashFlowCreditTransactionSort,
  setCashFlowDebitTransactionPagination,
  setCashFlowDebitTransactionSort,
  resetCashFlow,
  setSummaryTransactionSort,
} = cashFlowSlice.actions;

export default cashFlowSlice.reducer;
