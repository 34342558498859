import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getValueFromStorage, setValueToStorage } from 'hooks/useLocalStorage';
import { getCurrentUserApprovalTransactions } from 'services/TransactionApprovalService';
import { getLoggedUser, getUserPermissions } from 'services/UserService';
import { getCurrentWorkspace } from 'services/WorkspaceService';
import { resetStore } from 'store/actions';

import { ApiStatus, LocalStorageKeys } from 'constants/enums';
import { RolePermissions } from 'constants/permissionEnums';
import { JwtToken, User } from 'models/user.interface';
import { Workspace } from 'models/workspace.interface';
import { handleApiErrors } from 'utils/errorUtils';
import { auth } from 'utils/firebase';

export interface UserState {
  user: User|null;
  currentWorkspace: Workspace|null;
  loginWorkspaces: Workspace[];
  jwtToken: JwtToken|null;
  viewAs: User|null;
  isVerifyingPhone: boolean;
  viewAsWorkspaces: Workspace[];
  status: ApiStatus;
  permissions: RolePermissions[];
  pendingApprovalTransactions: number;
}

const initialState: UserState = {
  user: null,
  currentWorkspace: null,
  loginWorkspaces: [],
  jwtToken: null,
  viewAs: null,
  isVerifyingPhone: false,
  viewAsWorkspaces: [],
  status: ApiStatus.idle,
  permissions: [],
  pendingApprovalTransactions: 0,
};

export const fetchUserDetails = createAsyncThunk(
  'user/fetchUserDetails',
  async (_, thunkAPI) => {
    try {
      const [
        { data: workspace },
        { data: currentUser },
        { data: permissions },
      ] = await Promise.all([
        getCurrentWorkspace(),
        getLoggedUser(),
        getUserPermissions(),
      ]);

      return {
        workspace,
        currentUser,
        permissions,
      };
    } catch (e) {
      await auth.signOut();
      thunkAPI.dispatch(resetStore());
      thunkAPI.dispatch(resetUserState());
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchLoggedUserDetails = createAsyncThunk(
  'user/fetchLoggedUserDetails',
  async (_, thunkAPI) => {
    try {
      const { data: currentUser } = await getLoggedUser();
      return currentUser;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchCurrentUserApprovalTransactions = createAsyncThunk(
  'user/fetchCurrentUserApprovalTransactions',
  async (_, thunkAPI) => {
    try {
      const { data: pendingApprovalTransactions } = await getCurrentUserApprovalTransactions();
      return pendingApprovalTransactions;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchWorkspaceDetails = createAsyncThunk(
  'user/fetchWorkspaceDetails',
  async (_, thunkAPI) => {
    try {
      const { data: workspace } = await getCurrentWorkspace();

      return workspace;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

export const fetchUserPermissions = createAsyncThunk(
  'user/fetchUserPermissions',
  async (_, thunkAPI) => {
    try {
      const { data: permissions } = await getUserPermissions();
      return permissions;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);
export const fetchPendingTransactionApproval = createAsyncThunk(
  'user/fetchPendingTransactionApproval',
  async (_, thunkAPI) => {
    try {
      const { data } = await getCurrentUserApprovalTransactions();
      return data;
    } catch (e) {
      handleApiErrors(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);

//  TODO: viewAsWorkspaces should be fetch from BE by userId
//  TODO: handle currentWorkspace for viewAs, currently we read the first entry from viewAsWorkspace
export const userSlice = createSlice({
  name: 'loggedUser',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User|null>) => {
      state.user = action.payload;
      return state;
    },
    setCurrentWorkspace: (state, action: PayloadAction<Workspace|null>) => {
      state.currentWorkspace = action.payload;
      return state;
    },
    setUserWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      state.loginWorkspaces = action.payload;
      setValueToStorage(LocalStorageKeys.loginWorkspaces, action.payload);
      return state;
    },
    setJwtToken: (state, action: PayloadAction<JwtToken|null>) => {
      state.jwtToken = action.payload;
      setValueToStorage(
        LocalStorageKeys.jwtToken,
        action.payload ? { ...action.payload, activeAt: new Date().getTime() } : null,
      );
      return state;
    },
    setViewAs: (state, action: PayloadAction<User|null>) => {
      state.viewAs = action.payload;
      setValueToStorage(LocalStorageKeys.viewAs, action.payload);
      return state;
    },
    setViewAsWorkspaces: (state, action: PayloadAction<Workspace[]>) => {
      state.viewAsWorkspaces = action.payload;
      setValueToStorage(LocalStorageKeys.viewAsWorkspaces, action.payload);
      return state;
    },
    setIsVerifyingUser: (state, action: PayloadAction<boolean>) => {
      state.isVerifyingPhone = action.payload;
      return state;
    },
    initializeStoreWithStoredData: (state) => {
      try {
        return {
          ...state,
          loginWorkspaces: getValueFromStorage(LocalStorageKeys.loginWorkspaces, null),
          viewAs: getValueFromStorage(LocalStorageKeys.viewAs, null),
          viewAsWorkspaces: getValueFromStorage(LocalStorageKeys.viewAsWorkspaces, null),
          jwtToken: getValueFromStorage(LocalStorageKeys.jwtToken, null),
        };
      } catch (e) {
        return state;
      }
    },
    resetUserState: () => {
      setValueToStorage(LocalStorageKeys.jwtToken, null);
      setValueToStorage(LocalStorageKeys.viewAs, null);
      setValueToStorage(LocalStorageKeys.loginWorkspaces, null);
      setValueToStorage(LocalStorageKeys.viewAsWorkspaces, null);
      setValueToStorage(LocalStorageKeys.hideBrokenAccountsModal, null);
      setValueToStorage(LocalStorageKeys.hidePendingApprovalTransactions, null);

      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        const {
          workspace, currentUser, permissions,
        } = action.payload || {};

        state.currentWorkspace = workspace;
        state.user = currentUser;
        state.permissions = permissions || [];
      })
      .addCase(fetchLoggedUserDetails.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(fetchWorkspaceDetails.fulfilled, (state, action) => {
        state.currentWorkspace = action.payload;
      })
      .addCase(fetchCurrentUserApprovalTransactions.fulfilled, (state, action) => {
        state.pendingApprovalTransactions = action.payload;
      })
      .addCase(fetchUserPermissions.fulfilled, (state, action) => {
        state.permissions = action.payload;
      })
      .addCase(fetchPendingTransactionApproval.fulfilled, (state, action) => {
        state.pendingApprovalTransactions = action.payload;
      });
  },
});

export const {
  setUser,
  setCurrentWorkspace,
  setUserWorkspaces,
  setIsVerifyingUser,
  setJwtToken,
  setViewAs,
  setViewAsWorkspaces,
  initializeStoreWithStoredData,
  resetUserState,
} = userSlice.actions;

export default userSlice.reducer;
