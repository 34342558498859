import { Box, Typography } from '@mui/material';
import { differenceInSeconds } from 'date-fns';
import { isNil } from 'lodash';
import {
  FC, ReactNode, useEffect, useState,
} from 'react';
import { useIntl } from 'react-intl';

import CustomButton from 'components/CustomButton';
import CodeSection from 'containers/VerificationCode/CodeSection';

import { sendCode, verifyCode, verifyCodeFB } from 'services/VerificationCodeService';

import { VerificationStatus } from 'constants/enums';
import { VERIFICATION_CODE_MAX_COUNT } from 'constants/general';
import { handleApiErrors } from 'utils/errorUtils';
import { formatPhoneNumber, getPlainPhoneNumber } from 'utils/formatters';
import { VerificationCodeFormValues } from 'utils/validation/verificationCodeSchema';

let interval:any|null = null;

interface VerificationCodeProps {
  onSubmit: () => void;
  mb: number;
  triesCount: number;
  setTriesCount: (count: number) => void;
  phoneNumber: string;
  useFbToken?: boolean;
  maxWidth?: string;
}

const VerificationCode: FC<VerificationCodeProps> = ({
  onSubmit,
  mb = 0,
  triesCount,
  setTriesCount,
  phoneNumber,
  useFbToken = false,
  maxWidth = '370px',
}) => {
  const intl = useIntl();
  const [lastSentAt, setLastSentAt] = useState<number | null>(null);
  const [isResendDisable, setIsResendDisable] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const handleResend = async () => {
    try {
      const plainNumber = getPlainPhoneNumber(phoneNumber);
      await sendCode(plainNumber);
      setLastSentAt(new Date().getTime());
    } catch (e) {
      handleApiErrors(e);
    }
  };

  useEffect(() => {
    const diff = getDiffInSeconds();

    if (diff > 0) {
      setSeconds(diff);
      setIsResendDisable(true);
      startCheck();
    }
  }, [lastSentAt]);

  const getDiffInSeconds = () => {
    if (!isNil(lastSentAt)) {
      const secondsDiff = differenceInSeconds(new Date(), lastSentAt);
      return 60 - secondsDiff;
    }

    return 0;
  };

  const handleDisableState = () => {
    const diff = getDiffInSeconds();

    if (diff >= 0) {
      setSeconds(diff);
      setIsResendDisable(false);
    }

    if (diff <= 0) {
      stopCheck();
    }
  };

  const startCheck = () => {
    if (!interval) {
      interval = setInterval(handleDisableState, 1000);
    }
  };

  const stopCheck = () => {
    clearInterval(interval);
    interval = null;
  };

  const handleSubmit = async (verificationCode: VerificationCodeFormValues) => {
    setIsSubmitting(true);

    try {
      const plainNumber = getPlainPhoneNumber(phoneNumber);
      const code = Object.values(verificationCode).map((c) => c).join('');
      const fn = useFbToken ? verifyCodeFB : verifyCode;

      const response = await fn(plainNumber, code);

      if (response?.data?.status === VerificationStatus.approved) {
        await onSubmit();
      } else {
        setTriesCount(triesCount - 1);
      }
    } catch (e) {
      handleApiErrors(e);
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderBold = (chunks: ReactNode[]) => <b>{chunks}</b>;

  return (
    <Box mb={mb} maxWidth={maxWidth}>
      <Typography variant="body2" color="textSecondary" mb={4}>
        {intl.formatMessage(
          { id: 'label.confirmEnteringDigitCode' },
          { phoneNumber: formatPhoneNumber(phoneNumber), b: renderBold },
        )}
        {' '}
        {seconds
          ? (
            <Typography variant="body2" component="span">
              {intl.formatMessage({ id: 'label.tryAgainXSeconds' }, { seconds })}
            </Typography>
          ) : (
            <Typography
              variant="body2"
              component="span"
              color={isResendDisable ? 'general.lightGrey6' : 'primary'}
              className={isResendDisable ? 'default' : 'pointer'}
              onClick={handleResend}
              fontWeight={700}
            >
              {intl.formatMessage({ id: 'label.clickToResend' })}
            </Typography>
          )}
      </Typography>
      <CodeSection onSubmit={handleSubmit}>
        {triesCount !== VERIFICATION_CODE_MAX_COUNT && (
          <Typography component="p" variant="body3" fontWeight={600} color="error.main" mt={5}>
            {intl.formatMessage({ id: 'error.verificationSubmittedIncorrect' }, { count: triesCount })}
          </Typography>
        )}
        <CustomButton
          label={intl.formatMessage({ id: 'button.submit' })}
          id="button-submitCode"
          type="submit"
          isLoading={isSubmitting}
          fullWidth
          sx={{ mt: 10 }}
        />
      </CodeSection>
    </Box>
  );
};

export default VerificationCode;
