import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'store/index';

import { RolePermissions } from 'constants/permissionEnums';
import { JwtToken, User } from 'models/user.interface';
import { Workspace } from 'models/workspace.interface';

export const userSelector = (state: RootState): User|null => state.loggedUser.user;
export const rolePermissionsSelector = (state: RootState): RolePermissions[] => state.loggedUser.permissions;
export const userWorkspacesSelector = (state: RootState): Workspace[] => state.loggedUser.loginWorkspaces || [];
export const customerWorkspaceSelector = (state: RootState): Workspace|null => state.loggedUser.currentWorkspace;
export const jwtTokenSelector = (state: RootState): JwtToken|null => state.loggedUser.jwtToken;
export const viewAsSelector = (state: RootState): User|null => state.loggedUser.viewAs;
export const viewAsWorkspacesSelector = (state: RootState): Workspace[] => state.loggedUser.viewAsWorkspaces;
export const isVerifyingPhoneSelector = (state: RootState): boolean => state.loggedUser.isVerifyingPhone;
export const pendingApprovalTransactionsSelector = (state: RootState): number => (
  state.loggedUser.pendingApprovalTransactions
);

export const loginWorkspacesSelector = createSelector(
  viewAsSelector,
  userWorkspacesSelector,
  viewAsWorkspacesSelector,
  (viewAs, userWorkspaces, viewAsWorkspaces) => (
    viewAs ? viewAsWorkspaces : userWorkspaces
  ),
);

export const currentWorkspaceSelector = createSelector(
  viewAsSelector,
  customerWorkspaceSelector,
  viewAsWorkspacesSelector,
  (viewAs, customerWorkspace, viewAsWorkspaces) => (
    viewAs ? viewAsWorkspaces[0] : customerWorkspace
  ),
);
