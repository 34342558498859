import { styled } from '@mui/material/styles';
import MuiTableBody from '@mui/material/TableBody';

export const TableBody = styled(MuiTableBody)(({ theme }) => ({
  '& .MuiTableRow-root:not(:first-of-type):not(.collapsableRow) .MuiTableCell-root:not(.empty)': {
    borderTop: `2px solid ${theme.palette.general.lightGrey2}`,

    '&.dashed': {
      borderTop: `2px dashed ${theme.palette.general.lightGrey2}`,
    },
  },
}));
