import {
  FormHelperText, FormLabel, Menu, SxProps, Typography, Box,
} from '@mui/material';
import isEqual from 'lodash/isEqual';
import {
  FC, MouseEvent, ReactNode, useEffect, useState,
} from 'react';
import { useIntl } from 'react-intl';

import { BankImg } from 'containers/AdvancedAccountFilter/FilterHeader/index.styled';
import SearchableMenu from 'containers/SearchableMenu';

import ChevronDownIcon from 'assets/icons/ChevronDownIcon';
import ChevronUpIcon from 'assets/icons/ChevronUpIcon';
import { GenericOption } from 'models/option.interface';

import { DropdownWrapper, Button } from './index.styled';

interface AccountDropdownFieldProps {
  options: GenericOption[];
  onSelect: (value: GenericOption) => void;
  selectedOption: any;
  searchPlaceholder?: string;
  placeholder?: string;
  label?: string;
  hasError?: boolean;
  required?: boolean;
  disabled?: boolean;
  helperText?: string | ReactNode;
  sx?: SxProps;
}

const AccountDropdownField: FC<AccountDropdownFieldProps> = ({
  onSelect,
  options,
  selectedOption,
  hasError = false,
  required = false,
  disabled = false,
  label = '',
  helperText = '',
  searchPlaceholder = '',
  placeholder = '',
  sx = {},
}) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState<any>(null);

  useEffect(() => {
    if (!selectedOption) {
      setSelectedValue(null);
    }

    if (selectedOption && !isEqual(selectedOption, selectedValue)) {
      setSelectedValue(selectedOption);
    }
  }, [selectedOption]);

  const handleMenu = (event: MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      return;
    }

    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: any) => {
    if (value) {
      setSelectedValue(value);
      onSelect(value);
    }

    handleCloseMenu();
  };

  return (
    <DropdownWrapper
      hasError={hasError}
      display="inline-block"
      sx={sx}
    >
      {label && (
        <FormLabel required={required}>
          <Typography variant="label1" color="textPrimary">
            {label}
          </Typography>
        </FormLabel>
      )}

      <Button
        disabled={disabled}
        onClick={handleMenu}
        isActive={Boolean(anchorEl)}
        fullWidth
        endIcon={!anchorEl ? <ChevronDownIcon size={20} /> : <ChevronUpIcon size={20} />}
      >
        <Box display="flex" alignItems="center" gap={2} width="95%">
          {selectedValue?.icon && (
            <BankImg src={selectedValue.icon} alt={selectedValue?.formattedLabel || selectedValue.label} />
          )}
          <Typography
            variant="body2"
            noWrap
            color={selectedValue?.label ? 'text.primary' : 'general.lightGrey6'}
          >
            {selectedValue?.label
              ? selectedValue?.formattedLabel || selectedValue.label
              : intl.formatMessage({ id: placeholder })}
          </Typography>
        </Box>
      </Button>
      <Menu
        id="nested-dropdown"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        keepMounted
        slotProps={{
          paper: {
            sx: { mt: 1 },
            style: {
              width: anchorEl ? anchorEl.clientWidth : undefined, // Dynamically set the width
            },
          },
        }}
      >
        <SearchableMenu
          options={options}
          searchPlaceholder={searchPlaceholder}
          placeholder={placeholder}
          onSelect={handleSelect}
          minWidth="500px"
        />
      </Menu>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </DropdownWrapper>
  );
};

export default AccountDropdownField;
