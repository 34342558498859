import { runGetApiRequest, runPostApiRequest } from 'services/Api';

import { FAILED_TRANSACTION_DETAILS_API, FAILED_TRANSACTIONS_API } from 'constants/apiRoutes';
import { CreateTransactionPayload } from 'models/transaction.interface';
import { formatRoute } from 'utils/formatters';

export const getFailedTransactions = (params: { [key: string]: string | number | boolean }) => (
  runGetApiRequest(FAILED_TRANSACTIONS_API, params)
);

export const getFailedTransactionDetails = (transactionId: string) => (
  runGetApiRequest(formatRoute(FAILED_TRANSACTION_DETAILS_API, { transactionId }))
);

export const resubmitFailedTransaction = (transactionId: string, data: CreateTransactionPayload) => (
  runPostApiRequest(formatRoute(FAILED_TRANSACTION_DETAILS_API, { transactionId }), data)
);
