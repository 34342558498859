import { Box, Tooltip, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { UnorderList } from 'modules/rules-management/index.styled';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { useMaxAccounts } from 'containers/AdvancedAccountFilter/useAccountsMaxVisibility';

import FilterIcon from 'assets/icons/FilterIcon';
import { AmountComparison, DateType } from 'constants/enums';
import {
  AMOUNT_CONDITION_LABELS,
  BANK_ACCOUNT_TYPES_AS_LABELS,
  DATE_TYPE_LABELS,
  FILTER_TRANSACTION_TYPE_LABELS,
} from 'constants/labels';
import { BankAccount } from 'models/bankAccount.interface';
import { CashFlowFilterHeader } from 'models/cashFlow.interface';
import { getBankLogo } from 'utils/bank';
import { formatPrice, getAccountLast4Numbers } from 'utils/formatters';
import { capitalizeOneString, capitalize } from 'utils/text';

import {
  BankImg, Divider, Chip, Bubble,
} from './index.styled';

interface FilterHeaderProps {
  accounts: BankAccount[];
  asChip: boolean;
  filters: CashFlowFilterHeader;
  disabled: boolean;
  activeBankAccount?: string;
  setActiveBankAccount?: (account: string) => void;
}

const FilterHeader: FC<FilterHeaderProps> = ({
  accounts,
  filters,
  asChip,
  activeBankAccount,
  setActiveBankAccount,
  disabled,
}) => {
  const intl = useIntl();
  const { maxAccounts } = useMaxAccounts(filters, accounts?.length, asChip);
  const bankAccounts = accounts?.length > maxAccounts
    ? accounts?.slice(0, maxAccounts)
    : accounts;

  const getInterval = () => {
    if (!filters?.interval) {
      return null;
    }

    if (filters?.interval === DateType.custom) {
      return intl.formatMessage(
        { id: 'label.customDateRangeFromTo' },
        { from: filters?.dateFrom, to: filters?.dateTo },
      );
    }

    return intl.formatMessage({ id: DATE_TYPE_LABELS[filters?.interval] });
  };

  const handleAccountSelect = (e: any, account: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (setActiveBankAccount) {
      setActiveBankAccount(account);
    }
  };

  const renderRemainingAccounts = useMemo(() => {
    if (accounts?.length > maxAccounts) {
      const hiddenAccounts = accounts?.slice(maxAccounts, accounts?.length);
      return (
        <UnorderList>
          {hiddenAccounts.map((acc) => {
            const accountType = acc.type ? BANK_ACCOUNT_TYPES_AS_LABELS[acc.type] : '';

            return (
              <li key={acc.id}>
                {`${intl.formatMessage({ id: accountType })} (${getAccountLast4Numbers(acc.accountNumber)})`}
              </li>
            );
          })}
        </UnorderList>
      );
    }

    return null;
  }, [accounts, maxAccounts]);

  return (
    <Box id="filter-header" display="flex" alignItems="center" gap={5} width="calc(100% - 50px)">
      <Box display="flex">
        <FilterIcon size={24} />
      </Box>

      {filters?.interval && DATE_TYPE_LABELS?.[filters?.interval] && (
        <Typography variant="body2" fontWeight={600} className="text-ellipsis">
          {getInterval()}
        </Typography>
      )}

      {accounts?.length > 0 && (
        <>
          <Divider orientation="vertical" />
          <Box display="flex" alignItems="center" gap={3} flexWrap="wrap">
            <Typography variant="body2" fontWeight={600}>
              {`${capitalize(intl.formatMessage({ id: 'label.account' }))}: `}
            </Typography>
            {bankAccounts?.map(({
              accountNumber, type, institution, id, nickname, name,
            }) => {
              const accountType = type ? BANK_ACCOUNT_TYPES_AS_LABELS[type] : '';

              return (
                <Chip
                  onClick={asChip ? (e) => handleAccountSelect(e, id) : undefined}
                  asChip={asChip}
                  active={id === activeBankAccount}
                  key={id}
                  disabled={asChip && disabled}
                  label={(
                    <Box display="flex" alignItems="center" gap={2}>
                      <BankImg src={getBankLogo(institution)} alt={nickname || name} />
                      <Typography variant="body2" className="text-ellipsis">
                        {`${intl.formatMessage({ id: accountType })} (${getAccountLast4Numbers(accountNumber)})`}
                      </Typography>
                    </Box>
                  )}
                />
              );
            })}
            {accounts?.length > maxAccounts && (
              <Tooltip
                arrow
                title={renderRemainingAccounts}
                className="pointer"
                placement="right"
              >
                <Bubble>
                  <Typography variant="body2">
                    {`+${accounts?.length - maxAccounts}`}
                  </Typography>
                </Bubble>
              </Tooltip>
            )}
          </Box>
        </>
      )}

      {!isNil(filters?.type) && FILTER_TRANSACTION_TYPE_LABELS?.[filters?.type] && (
        <>
          <Divider orientation="vertical" />

          <Typography variant="body2" fontWeight={600} className="text-ellipsis">
            {intl.formatMessage({ id: FILTER_TRANSACTION_TYPE_LABELS[filters?.type] })}
          </Typography>
        </>
      )}

      {filters?.amountCondition && (
        <>
          <Divider orientation="vertical" />

          <Typography variant="body2" fontWeight={600} className="text-ellipsis">
            {capitalizeOneString(intl.formatMessage({ id: AMOUNT_CONDITION_LABELS[filters?.amountCondition] }))}
            {' '}
            {formatPrice({ price: filters?.amountFrom })}
            {filters?.amountCondition === AmountComparison.between && filters?.amountTo && (
              ` ${intl.formatMessage({ id: 'label.and' })} ${formatPrice({ price: filters?.amountTo })}`
            )}
          </Typography>
        </>
      )}

      {filters?.keywords && (
        <>
          <Divider orientation="vertical" />

          <Typography variant="body2" fontWeight={600} className="text-ellipsis">
            {filters?.keywords}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default FilterHeader;
