import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { Grid2 as Grid, Box, Paper } from '@mui/material';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import DateForm from 'components/DateForm';
import InsightsEmptyState from 'components/InsightsEmptyState';
import AdvancedAccountFilter from 'containers/AdvancedAccountFilter';
import AccountsFilter from 'containers/AdvancedAccountFilter/AccountsFilter';
import DonutChart from 'modules/insights-summary/components/DonutChart';
import TotalVolumeSection from 'modules/insights-summary/components/TotalVolumeSection';
import AccountsActivity from 'modules/insights-summary/containers/AccountsActivity';
import { Content } from 'modules/insights-summary/containers/InsightsSummary/index.styled';
import SummaryTransactions from 'modules/insights-summary/containers/SummaryTransactions';

import { useInsightsFilters } from 'hooks/useInsightsFilters';
import { useAppDispatch, useAppSelector } from 'hooks/useRedux';
import { saveUserFilterPreferences } from 'services/UserService';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { fetchSummaryTransactions, resetCashFlow } from 'store/cash-flow/cashFlowSlice';
import {
  isLoadingSummaryTransactionsSelector, summaryDetailsSelector,
  summaryTransactionsSortOptionsSelector,
} from 'store/cash-flow/selectors';
import { currentWorkspaceSelector, userSelector } from 'store/user/selectors';

import { InsightsComponents } from 'constants/enums';
import { GENERAL_TOAST_OPTIONS } from 'constants/general';
import { GenericOption } from 'models/option.interface';
import { DEFAULT_FILTERS } from 'modules/insights-summary/constants';
import { formatFiltersForHeader, formatFiltersForServer } from 'modules/insights-summary/utils';
import { handleApiErrors } from 'utils/errorUtils';
import { cashflowSchema, CashflowSchema } from 'utils/validation/cashflowSchema';

const MAX_ACCOUNTS = 5;
const MIN_ACCOUNTS = 2;

const InsightsSummary = () => {
  const dispatch = useAppDispatch();
  const intl = useIntl();
  const currentWorkspace = useAppSelector(currentWorkspaceSelector);
  const loggedUser = useAppSelector(userSelector);
  const { data: bankAccounts, isLoading: isLoadingAccounts } = useAppSelector(accountsSelector, isEqual);
  const sortOptions = useAppSelector(summaryTransactionsSortOptionsSelector, isEqual);
  const isLoading = useAppSelector(isLoadingSummaryTransactionsSelector, isEqual);
  const total = useAppSelector(summaryDetailsSelector, isEqual);

  const {
    activeBankAccount,
    setActiveBankAccount,
    tempAccounts,
    setTempAccounts,
    selectedAccounts,
    setSelectedAccounts,
    filters,
    setFilters,
    onResetAll,
    setHasSavedFilters,
    hasSavedFilters,
  } = useInsightsFilters<CashflowSchema>({
    defaultFilters: DEFAULT_FILTERS,
    pageName: InsightsComponents.moneyMovement,
  });
  const workspaceId = currentWorkspace?.id || '';

  const [expanded, setExpanded] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors, isDirty },
  } = useForm<CashflowSchema>({
    resolver: zodResolver(cashflowSchema),
    mode: 'onSubmit',
    defaultValues: DEFAULT_FILTERS,
  });

  useEffect(() => {
    reset(filters);
  }, [filters]);

  useEffect(() => () => {
    dispatch(resetCashFlow());
  }, []);

  useEffect(() => {
    if (!activeBankAccount || !selectedAccounts?.length) {
      return;
    }

    const selectedIds = selectedAccounts?.map((account) => account.value);
    const toIds = activeBankAccount ? selectedIds?.filter((id) => id !== activeBankAccount) : [];

    dispatch(fetchSummaryTransactions({
      sort: `${sortOptions.name},${sortOptions.type}`,
      bankAccountIdsFrom: activeBankAccount ? [activeBankAccount] : [],
      bankAccountIdsTo: toIds,
      ...formatFiltersForServer(filters),
    }));
  }, [sortOptions, filters, activeBankAccount, selectedAccounts]);

  const isFormDirty = useMemo(() => {
    if (isDirty) {
      return true;
    }

    const newIds = tempAccounts?.map((acc) => acc?.value).filter((value) => value);
    return !isEqual(newIds, selectedAccounts);
  }, [isDirty, tempAccounts, selectedAccounts]);

  const onSubmit = (newFilters: CashflowSchema) => {
    if (!isEqual(newFilters, filters)) {
      setFilters(newFilters);
      reset(newFilters);
    }

    const newSelectedAccounts = [...tempAccounts.filter((account) => account?.value) as GenericOption[]];

    if (!isEqual(selectedAccounts, tempAccounts)) {
      setSelectedAccounts(newSelectedAccounts);
    }

    setExpanded(false);

    const containsActiveAccount = newSelectedAccounts?.some((account) => account?.value === activeBankAccount);

    handleSavePreferences({
      accounts: newSelectedAccounts,
      currentFilters: newFilters,
      activeAccount: containsActiveAccount ? activeBankAccount : '',
      onSuccess: () => setHasSavedFilters(true),
    });
  };

  const onCancel = () => {
    reset(DEFAULT_FILTERS);
    setTempAccounts(selectedAccounts);
    setExpanded(false);
  };

  const isSaveDisabled = tempAccounts.filter((account) => account?.value)?.length < MIN_ACCOUNTS
    || (isEqual(tempAccounts.filter((account) => account?.value), selectedAccounts) && !isFormDirty);

  const handleActiveAccount = (account: string) => {
    if (account !== activeBankAccount) {
      setActiveBankAccount(account);
      handleSavePreferences({
        accounts: selectedAccounts,
        currentFilters: filters,
        activeAccount: account,
      });
    }
  };

  const handleSavePreferences = async ({
    accounts,
    currentFilters,
    activeAccount,
    onSuccess,
  }: {
    accounts: GenericOption[];
    currentFilters: CashflowSchema;
    activeAccount?: string;
    onSuccess?: () => void;
  }) => {
    if (!loggedUser?.id) {
      toast.error(intl.formatMessage({ id: 'error.missingInfoPreferences' }), GENERAL_TOAST_OPTIONS);
      return;
    }

    try {
      const payloadFilters: Record<string, any> = {
        ...formatFiltersForServer(currentFilters),
        bankAccountIds: accounts.map(({ value }) => value).join(','),
        ...(activeAccount ? { activeBankAccount: activeAccount } : {}),
      };
      const payload = {
        pageName: InsightsComponents.moneyMovement,
        filter: Object.entries(payloadFilters)?.map(([element, value]) => ({ element, value })),
      };

      await saveUserFilterPreferences(workspaceId, loggedUser?.id, payload);

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      handleApiErrors(e);
    }
  };

  if (!bankAccounts?.length) {
    return <InsightsEmptyState isLoading={isLoadingAccounts} />;
  }

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <AdvancedAccountFilter
        accounts={selectedAccounts}
        onSubmit={handleSubmit(onSubmit)}
        onCancel={onCancel}
        disabled={isSaveDisabled}
        asChip
        activeBankAccount={activeBankAccount}
        setActiveBankAccount={handleActiveAccount}
        isExpanded={expanded}
        onSetIsExpanded={setExpanded}
        onResetAll={onResetAll}
        hasResetAll={hasSavedFilters}
        filters={formatFiltersForHeader(filters)}
      >
        <Grid container>
          <Grid size={{ xs: 8 }}>
            <AccountsFilter
              selectedAccounts={tempAccounts}
              setSelectedAccounts={setTempAccounts}
              maxAccounts={MAX_ACCOUNTS}
              minAccounts={MIN_ACCOUNTS}
              errorMessage={intl.formatMessage({ id: 'error.selectAtLeastAccounts' })}
            />
          </Grid>
          <Grid size={{ xs: 4 }}>
            <DateForm
              <CashflowSchema>
              register={register}
              watch={watch}
              control={control}
              errors={errors}
              required
            />
          </Grid>
        </Grid>
      </AdvancedAccountFilter>
      <Content>
        <Grid container spacing={2}>
          <Grid size={4} pt={7} pb={3} px={6}>
            <Box display="flex" flexDirection="column" gap={8} width="100%">
              <Box display="flex" justifyContent="center">
                <DonutChart
                  isLoading={isLoading}
                  totalVolume={total?.totalVolume || 0}
                  creditsVolume={total?.creditVolume || 0}
                />
              </Box>
              <Box display="flex" flexDirection="column" gap={5}>
                <TotalVolumeSection
                  volume={total?.creditVolume || 0}
                  amount={total?.creditValue || 0}
                  isCredit
                  isLoading={isLoading}
                />
                <TotalVolumeSection
                  volume={total?.debitVolume || 0}
                  amount={total?.debitValue || 0}
                  isLoading={isLoading}
                />
              </Box>
            </Box>
          </Grid>
          <Grid size={8}>
            <Paper elevation={0} className="medium">
              <AccountsActivity
                isLoading={isLoading}
                selectedAccounts={activeBankAccount
                  ? selectedAccounts?.filter(({ value }) => value !== activeBankAccount)
                  : selectedAccounts}
              />
              <SummaryTransactions />
            </Paper>
          </Grid>
        </Grid>
      </Content>
    </Box>
  );
};

export default InsightsSummary;
