import {
  Box, Skeleton, Typography, Grid2 as Grid,
} from '@mui/material';
import { isEqual } from 'lodash';
import { FC, useMemo } from 'react';
import { useIntl } from 'react-intl';

import ActivityLegendItem from 'components/ActivityLegendItem';
import ActivityRankedChart from 'containers/ActivityRankedChart';

import { useAppSelector } from 'hooks/useRedux';
import { accountsSelector } from 'store/bank-accounts/selectors';
import { summaryTransactionsSelector } from 'store/cash-flow/selectors';

import { GenericOption } from 'models/option.interface';
import {
  CREDITS_CHART_LABEL,
  DEBITS_CHART_LABEL,
  TOTAL_TRANSACTIONS_CHART_LABEL,
} from 'utils/activityChartConfig';
import { getBankLogo } from 'utils/bank';
import { formatAcctNumberAndName } from 'utils/formatters';

import { BankImg } from './index.styled';

interface AccountsActivityProps {
  selectedAccounts: GenericOption[];
  isLoading: boolean;
}

const AccountsActivity: FC<AccountsActivityProps> = ({
  selectedAccounts,
  isLoading,
}) => {
  const intl = useIntl();
  const { data: bankAccounts } = useAppSelector(accountsSelector, isEqual);
  const transactions = useAppSelector(summaryTransactionsSelector, isEqual);

  const displayAccounts = useMemo(() => {
    const ids = selectedAccounts?.map((acc) => acc?.value).filter((value) => value);
    return ids?.length > 0
      ? bankAccounts?.filter(({ id }) => ids.includes(id)) || []
      : [];
  }, [bankAccounts, selectedAccounts]);

  const chartData = useMemo(() => (
    transactions
      ? transactions?.map(({
        account,
        creditVolume,
        creditValue,
        debitVolume,
        debitValue,
      }) => ({
        accountNumber: account?.accountNumber,
        accountId: account?.id,
        institutionCode: account?.institution?.code || '',
        institutionName: account?.institution?.name || '',
        institutionLogo: account?.institution?.smallLogoUrl || null,
        totalBalance: 0,
        totalNumberTransactions: debitVolume + creditVolume,
        totalNumberDebits: debitVolume,
        totalNumberCredits: creditVolume,
        totalAmountDebits: debitValue,
        totalAmountCredits: creditValue,
        totalAmount: creditValue - debitValue,
      }))
      : []
  ), [transactions]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="subtitle1" fontWeight={700} color="textSecondary">
          {intl.formatMessage({ id: 'label.accountsActivity' })}
        </Typography>
        <Typography variant="body3" color="general.darkGrey1">
          {intl.formatMessage({ id: 'label.graphShowsSelected' })}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Box display="flex" alignItems="center" gap={3}>
          <ActivityLegendItem
            legendItem={TOTAL_TRANSACTIONS_CHART_LABEL}
            label={intl.formatMessage({ id: TOTAL_TRANSACTIONS_CHART_LABEL.label })}
          />
          <ActivityLegendItem
            legendItem={CREDITS_CHART_LABEL}
            label={intl.formatMessage({ id: CREDITS_CHART_LABEL.label })}
          />
          <ActivityLegendItem
            legendItem={DEBITS_CHART_LABEL}
            label={intl.formatMessage({ id: DEBITS_CHART_LABEL.label })}
          />
        </Box>
      </Box>
      <Grid container mt={6} columnSpacing={5}>
        <Grid size={{ xs: 6 }}>
          <Box display="flex" flexDirection="column" gap={4}>
            {displayAccounts?.map((account) => {
              const name = formatAcctNumberAndName({
                accountNumber: account?.accountNumber,
                accountName: account?.name,
                isAccountWrapped: true,
              });

              return (
                <Box display="flex" alignItems="center" gap={1} key={account?.id}>
                  <BankImg src={getBankLogo(account?.institution)} alt={name} />
                  <Typography variant="label1">
                    {name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Grid>
        <Grid size={{ xs: 6 }}>
          {isLoading
            ? <Skeleton animation="wave" variant="rectangular" height={157} width="100%" />
            : <ActivityRankedChart data={chartData} />}
        </Grid>
      </Grid>
    </>
  );
};

export default AccountsActivity;
