import { Box, Grid2 as Grid, Typography } from '@mui/material';

import Content from 'components/_Content';
import PageHeading from 'components/PageHeading';

import { icons, largeIcons } from './icons';

const IconsList = () => (
  <Box sx={{ overflow: 'auto', height: '100vh' }}>
    <Content>
      <PageHeading title="Icons" mb={10} />

      <Grid container spacing={4}>
        {icons.map(({ Icon, name }) => (
          <Grid size={{ xs: 2 }} key={name}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Icon size={24} color="#000" />
              <Typography variant="body2" sx={{ mt: 2 }}>
                {name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <PageHeading title="Large Icons" sx={{ my: 10 }} />

      <Grid container spacing={4}>
        {largeIcons.map(({ Icon, name }) => (
          <Grid size={{ xs: 2 }} key={name}>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Icon />
              <Typography variant="body2" sx={{ mt: 2 }}>
                {name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Content>
  </Box>
);

export default IconsList;
