import { runGetApiRequest } from 'services/Api';

import { MONEY_MOVEMENT_CONSOLIDATED_API, MONEY_MOVEMENT_API } from 'constants/apiRoutes';
import { MoneyMovementQueryParams } from 'models/cashFlow.interface';

export const getMoneyMovement = (queryParams: MoneyMovementQueryParams) => {
  const requestParams = {
    ...queryParams,
    bankAccountIdsFrom: queryParams?.bankAccountIdsFrom?.join(',') || '',
    bankAccountIdsTo: queryParams?.bankAccountIdsTo?.join(',') || '',
  };
  return runGetApiRequest(MONEY_MOVEMENT_API, requestParams);
};

export const getMoneyMovementConsolidated = (queryParams: MoneyMovementQueryParams) => {
  const requestParams = {
    ...queryParams,
    bankAccountIdsFrom: queryParams?.bankAccountIdsFrom?.join(',') || '',
    bankAccountIdsTo: queryParams?.bankAccountIdsTo?.join(',') || '',
  };
  return runGetApiRequest(MONEY_MOVEMENT_CONSOLIDATED_API, requestParams);
};
