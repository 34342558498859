import { PhoneNumberUtil } from 'google-libphonenumber';

import { SUPPORTED_PHONE_NUMBER_COUNTRIES } from 'constants/countries';
import { REGEX_EMAIL, REGEX_WEBSITE } from 'constants/regex';

export const checkValidPhone = (value: string): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();

  if (!value) {
    return true;
  }

  try {
    const phoneNumber = phoneUtil.parse(value, '');
    const countryCode = phoneUtil.getRegionCodeForNumber(phoneNumber);

    if (countryCode
      && phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)
      && SUPPORTED_PHONE_NUMBER_COUNTRIES.includes(countryCode.toLowerCase())
    ) {
      return true;
    }
  } catch (error) {
    return false;
  }

  return false;
};

export const checkEmail = (value: string): boolean => {
  if (!value) {
    return true;
  }

  try {
    return REGEX_EMAIL.test(value);
  } catch (error) {
    return false;
  }
};

export const checkUrl = (value: string): boolean => {
  if (!value) {
    return true;
  }

  try {
    return REGEX_WEBSITE.test(value);
  } catch (error) {
    return false;
  }
};
