import { AmountComparison, DateType } from 'constants/enums';
import { BankAccount } from 'models/bankAccount.interface';
import { FilterPreference } from 'models/filterPreference.interface';
import { FILTER_SIGN_BY_AMOUNT_COMPARISON } from 'modules/insights-advanced-search/constants';

import { formatStringDate } from './formatters';

export const formatPreferenceFiltersToInsightsFilters = (
  preferenceFilters: FilterPreference[],
  bankAccounts: BankAccount[],
) => {
  let filters: any = {
    dateRange: {},
  };
  const foundBankAccounts: BankAccount[] = [];

  preferenceFilters?.forEach(({ element, value }) => {
    if (element === 'interval') {
      filters = {
        ...filters,
        dateRange: {
          ...filters?.dateRange,
          type: value,
        },
      };
    }

    if (element === 'description') {
      filters = {
        ...filters,
        keywords: value,
      };
    }

    if (element === 'dateFrom') {
      filters = {
        ...filters,
        dateRange: {
          ...filters?.dateRange,
          from: new Date(formatStringDate(value)).getTime(),
          type: DateType.custom,
        },
      };
    }

    if (element === 'dateTo') {
      filters = {
        ...filters,
        dateRange: {
          ...filters?.dateRange,
          to: new Date(formatStringDate(value)).getTime(),
          type: DateType.custom,
        },
      };
    }

    if (element === 'type') {
      filters = {
        ...filters,
        transactionType: value,
      };
    }

    if (element === 'amountFilterValue') {
      filters = {
        ...filters,
        amountRange: {
          ...(filters?.amountRange || {}),
          min: (+value).toFixed(2),
        },
      };
    }

    if (element === 'amountFilterSign') {
      let amountCondition = '';

      Object.entries(FILTER_SIGN_BY_AMOUNT_COMPARISON).forEach(([condition, sign]) => {
        if (sign === value) {
          amountCondition = condition;
        }
      });

      filters = {
        ...filters,
        amountCondition,
      };
    }

    if (element === 'amountFrom') {
      filters = {
        ...filters,
        amountCondition: AmountComparison.between,
        amountRange: {
          ...(filters?.amountRange || {}),
          min: (+value).toFixed(2),
        },
      };
    }

    if (element === 'amountTo') {
      filters = {
        ...filters,
        amountCondition: AmountComparison.between,
        amountRange: {
          ...(filters?.amountRange || {}),
          max: (+value).toFixed(2),
        },
      };
    }

    if (element === 'bankAccountIds') {
      const bankAccountIds = value.split(',');

      bankAccountIds?.forEach((accountId: string) => {
        const foundAccount = bankAccounts.find(({ id }) => id === accountId);

        if (foundAccount) {
          foundBankAccounts.push(foundAccount);
        }
      });
    }

    if (element === 'activeBankAccount') {
      filters = {
        ...filters,
        activeBankAccount: value,
      };
    }
  });

  return { formattedFilters: filters, foundBankAccounts };
};
