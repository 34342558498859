import { isNil } from 'lodash';
import { z } from 'zod';

import {
  AmountComparison,
  DateType, DefaultType, TransactionFilterTypes,
} from 'constants/enums';
import {
  DAY_LIMIT,
  MONTH_LIMIT,
  SINGLE_LIMIT,
  WEEK_LIMIT,
} from 'constants/fields';

const amountSchema = z.object({
  min: z.string().nullable().optional(),
  max: z.string().nullable().optional(),
}).refine((values) => {
  const { min, max } = values;

  if (!isNil(min) && !isNil(max) && +min > +max) {
    return false;
  }

  return true;
}, {
  message: 'error.amountRange',
});

const dateRangeSchema = z.object({
  from: z
    .number()
    .nullable()
    .optional(),
  to: z
    .number()
    .nullable()
    .optional(),
})
  .refine((values) => {
    const { from, to } = values;

    if (from && to && from > to) {
      return false;
    }

    return true;
  }, {
    message: 'error.dateRange',
  });

export const transactionFilterSchema = z.object({
  types: z.object({
    [DefaultType.all]: z.boolean().optional(),
    [TransactionFilterTypes.inflow]: z.boolean().optional(),
    [TransactionFilterTypes.outflow]: z.boolean().optional(),
  }),
  banks: z.record(z.boolean().optional()).optional(),
  dateRange: dateRangeSchema,
  amountRange: amountSchema,
});

export const scheduledTransactionFilterSchema = z.object({
  dateRange: dateRangeSchema,
  amountRange: amountSchema,
});

export const approvalTransactionFilterSchema = z.object({
  dateRange: dateRangeSchema,
  amountRange: amountSchema,
});

export type TransactionFilterSchema = z.infer<typeof transactionFilterSchema>;
export type ScheduledTransactionFilterSchema = z.infer<typeof scheduledTransactionFilterSchema>;
export type ApprovalTransactionFilterSchema = z.infer<typeof approvalTransactionFilterSchema>;

export const transactionLimitsFormSchema = z.object({
  [SINGLE_LIMIT.name]: z
    .string()
    .optional(),
  [DAY_LIMIT.name]: z
    .string()
    .optional(),
  [WEEK_LIMIT.name]: z
    .string()
    .optional(),
  [MONTH_LIMIT.name]: z
    .string()
    .optional(),
});

export type TransactionLimitsSchema = z.infer<typeof transactionLimitsFormSchema>;

export const recipientTransactionFilterSchema = z.object({
  dateRange: dateRangeSchema,
  amountRange: amountSchema,
  types: z.object({
    ALL: z.boolean().optional(),
    send: z.boolean().optional(),
    request: z.boolean().optional(),
  }),
  latestStatus: z.string().optional(),
});

export type RecipientTransactionFilterSchema = z.infer<typeof recipientTransactionFilterSchema>;

export const activityTransactionFilterSchema = z.object({
  dateRange: dateRangeSchema,
  amountRange: amountSchema,
  latestStatus: z.string().optional(),
});

export type ActivityTransactionFilterSchema = z.infer<typeof activityTransactionFilterSchema>;

export const advancedTransactionFilterSchema = z.object({
  dateRange: z.object({
    type: z.string().optional(),
    from: z.number().nullable().optional(),
    to: z.number().nullable().optional(),
  })
    .refine((values) => {
      const { from, to, type } = values;
      return type === DateType.custom ? !!from && !!to : true;
    }, {
      message: 'error.dateRangeMandatoryFields',
    })
    .refine((values) => {
      const { from, to, type } = values;

      if (type !== DateType.custom) {
        return true;
      }

      if (from && to && from > to) {
        return false;
      }

      return true;
    }, {
      message: 'error.dateRange',
    })
    .refine((values) => {
      const { from, to, type } = values;
      const minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).setHours(0, 0, 0, 0);
      const maxDate = new Date().setHours(23, 59, 59, 59);

      if (type !== DateType.custom) {
        return true;
      }

      if (from && (from < minDate || from > maxDate)) {
        return false;
      }

      if (to && (to < minDate || to > maxDate)) {
        return false;
      }

      return true;
    }, {
      message: 'error.selectedTimeframe.oneYear',
    }),
  amountRange: z.object({
    min: z.string().nullable().optional(),
    max: z.string().nullable().optional(),
  }),
  amountCondition: z.string().optional(),
  keywords: z.string().optional(),
  transactionType: z.string().optional(),
})
  .refine((values) => {
    const { amountRange, amountCondition } = values;

    if (amountCondition !== AmountComparison.between) {
      return true;
    }

    if (!isNil(amountRange?.min) && !isNil(amountRange?.max) && +amountRange?.min > +amountRange?.max) {
      return false;
    }

    return true;
  }, {
    message: 'error.amountRange',
    path: ['amountRange'],
  })
  .refine((values) => {
    const { amountRange, amountCondition } = values;
    return amountCondition === AmountComparison.between
      ? !!amountRange?.min && !!amountRange?.max
      : true;
  }, {
    message: 'error.amountRangeMandatoryFields',
    path: ['amountRange'],
  });

export type AdvancedTransactionFilterSchema = z.infer<typeof advancedTransactionFilterSchema>;
