import { Box } from '@mui/material';
import { ReactElement, FC } from 'react';
import { Outlet } from 'react-router-dom';

import Navbar from 'components/_Navbar';
import ActAsCustomerBanner from 'components/ActAsCustomerBanner';
import { MainContent } from 'layouts/MainLayout/index.styled';
import CustomSuspense from 'routes/CustomSuspense';

import useLocalStorage from 'hooks/useLocalStorage';

import { LocalStorageKeys } from 'constants/enums';

const MainLayout:FC = (): ReactElement => {
  const [storedViewAs] = useLocalStorage(LocalStorageKeys.viewAs);
  return (
    <Box>
      <Navbar />
      <MainContent id="mainContent" storedViewAs={storedViewAs}>
        <CustomSuspense>
          <Outlet />
        </CustomSuspense>
      </MainContent>
      {storedViewAs && (
        <ActAsCustomerBanner />
      )}
    </Box>
  );
};

export default MainLayout;
